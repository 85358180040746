import { createRouter, createWebHistory } from "vue-router";
//import HomeView from "../views/index.vue";
import authLayout1 from "../layout/authLayout1.vue";
import authLayout2 from "../layout/authLayout2.vue";
import mainLayout from "../layout/mainLayout.vue";
import titlepanelLayout from "../layout/titlepanelLayout.vue";
import simpleHeaderLayout from "../layout/simpleHeaderLayout.vue";
import { useUserTokenStore } from "@/stores/useUserTokenStore";
import { App } from '@capacitor/app';
import {useCommonStore} from "@/stores/useCommonStore";

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0);
    const commonStore = useCommonStore();
    commonStore.setRedToastMessage("", 0);
    return { x: 0, y: 0,top:0,left:0 };
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "main",
      meta: {
        layout: mainLayout,
        requiresAuth: true,
      },
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("../views/index.vue"),
        },
        {
          path: "/loading",
          name: "home2",
          component: () => import("../views/loadings/index-loading.vue"),
        },
        {
          path: "management",
          name: "user_management",
          children: [
            {
              path: "index",
              name: "user_management_index",
              component: () => import("../views/management/index.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/",
      meta: {
        layout: simpleHeaderLayout,
        requiresAuth: true,
      },
      children: [

        {
          path: "wallet",
          name: "wallet-managment",
          children: [
            {
              path: "index",
              name: "wallet_index",
              component: () => import("../views/wallet/index.vue"),
            },
          ],
        },
        {
          path: "market",
          name: "market",
          component: () => import("../views/market/index.vue"),
        },
        {
          path: "buyandsell",
          name: "buyandsell",
          component: () => import("../views/market/buyAndsell.vue"),
        },
      ],
    },
    {
      path: "/user",
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "wallet/callback",
          name: "wallet-callback",
          component: () => import("../views/wallet/callback.vue"),
        },
      ],
    },
    {
      path: "/user",
      name: "user-parent",
      meta: {
        layout: titlepanelLayout,
        requiresAuth: true,
      },
      children: [
        {
          path: "wdadmin-report/:wtid",
          name: "wdadmin-report",
          component: () => import("../views/wallet/wdAdminReport.vue"),
        },
        {
          path: "order-history/:factor_type_query?",
          name: "order-history",
          component: () => import("../views/management/orderhistory.vue"),
        },
        {
          path: "order-history-details/:factor_id",
          name: "order-history-details",
          component: () => import("../views/management/orderhistoryDetails.vue"),
        },
        {
          path: "bankcards",
          name: "bankcards-index",
          component: () => import("../views/management/cardbank/index.vue"),
        },
        {
          path: "bankcards/add",
          name: "bankcards-add",
          component: () => import("../views/management/cardbank/add.vue"),
        },
        {
          path: "wallets",
          name: "wallets-index",
          component: () => import("../views/management/wallets/index.vue"),
        },
        {
          path: "wallets/add",
          name: "wallets-add",
          component: () => import("../views/management/wallets/add.vue"),
        },
        {
          path: "security",
          name: "security-index",
          component: () => import("../views/management/security/index.vue"),
        },
        {
          path: "security-history",
          name: "security-history",
          component: () => import("../views/management/security/history.vue"),
        },
        {
          path: "changepassword",
          name: "security-changepassword",
          component: () => import("../views/management/security/changepassword.vue"),
        },
        {
          path: "bankcards/transactions/:card_id/:card_number",
          name: "bankcards-transactions",
          component: () => import("../views/management/cardbank/transactions.vue"),
        },
        {
          path: "view-level",
          name: "view-level",
          component: () => import("../views/authorise/viewlevel.vue"),
        },
        {
          path: "all-levels",
          name: "all-levels",
          component: () => import("../views/authorise/allLevels.vue"),
        },
        {
          path: "roadmap",
          name: "roadmap",
          component: () => import("../views/authorise/roadmap.vue"),
        },
        {
          path: "authorise",
          name: "authorise-parent",
          children: [
            {
              path: "substitute",
              name: "substitute",
              component: () => import("../views/authorise/substitute.vue"),
            },
            {
              path: "first-time-auth",
              name: "first-time-auth",
              component: () => import("../views/authorise/firstTimeAuth.vue"),
            },
            {
              path: "terms",
              name: "terms",
              component: () => import("../views/authorise/terms.vue"),
            },
            {
              path: "contact-info",
              name: "contactInfo",
              component: () => import("../views/authorise/lv1/contact_info.vue"),
            },
            {
              path: "identity-info",
              name: "identityInfo",
              component: () => import("../views/authorise/lv1/identity_info.vue"),
            },
            {
              path: "google-authenticator",
              name: "googleauthenticate",
              component: () => import("../views/authorise/lv2/googleAuthActivator.vue"),
            },
            {
              path: "uploadLv1",
              name: "uploadLv1",
              children: [
                {
                  path: "uploadLv1-index",
                  name: "uploadLv1-index",
                  component: () => import("../views/authorise/lv1/docs/index.vue"),
                },
                {
                  path: "birthdayid-mode",
                  name: "birthdayid-mode",
                  component: () => import("../views/authorise/lv1/docs/birthdayIDMode.vue"),
                },
                {
                  path: "uploadLv1-idcard",
                  name: "uploadLv1-nationalcard",
                  component: () => import("../views/authorise/lv1/docs/uploadNationalCard.vue"),
                },
                {
                  path: "uploadLv1-birthdaycard/:view",
                  name: "uploadLv1-birthdaycard",
                  component: () => import("../views/authorise/lv1/docs/uploadBirthDayCard.vue"),
                },
                {
                  path: "uploadLv1-video-selfi",
                  name: "uploadLv1-video-selfi",
                  component: () => import("../views/authorise/lv1/docs/videoSelfi.vue"),
                },
              ],
            },
            {
              path: "levelup",
              name: "levelup",
              component: () => import("../views/authorise/lv2_3/index.vue"),
            },
          ],
        },
        {
          path: "support",
          name: "support-parent",
          children: [
            {
              path: "support-index",
              name: "support-index",
              component: () => import("../views/management/support/index.vue"),
            },
            {
              path: "support-add-ticket",
              name: "support-add-ticket",
              component: () => import("../views/management/support/newTicket.vue"),
            },
            {
              path: "support-tickets",
              name: "support-tickets",
              component: () => import("../views/management/support/Tickets.vue"),
            },
            {
              path: "ticket/:ticket_id",
              name: "support-view-ticket",
              component: () => import("../views/management/support/ticketView.vue"),
            },
          ],
        },
        {
          path: "wallet-offline-index",
          name: "wallet-offline-index",
          component: () => import("../views/wallet/offlineDeposit.vue"),
        },
        {
          path: "wallet-offline-submit",
          name: "wallet-offline-submit",
          component: () => import("../views/wallet/offlineDepositSubmit.vue"),
        },
        {
          path: "wallet-wd-request/:wd_id",
          name: "wallet-wd-request",
          component: () => import("../views/wallet/wdrequestInfo.vue"),
        },
        {
          path: "wallet-deposit",
          name: "wallet-deposit",
          component: () => import("../views/wallet/deposit.vue"),
        },

        {
          path: "wallet-withdraw",
          name: "wallet-withdraw",
          component: () => import("../views/wallet/withdraw.vue"),
        },
        {
          path: "notifications",
          name: "notification-index",
          component: () => import("../views/Notification/notificationIndex.vue"),
        },
        {
          path: "view-notification",
          name: "notification-view",
          component: () => import("../views/Notification/notificationView.vue"),
        },
        {
          path: "checkout/:factor_id",
          name: "checkout",
          component: () => import("../views/factor/checkout.vue"),
        },
        {
          path: "market/currency/:symbol",
          name: "market-currency",
          component: () => import("../views/market/currency.vue"),
        },
        {
          path: "ibanpay",
          name: "ibanpay",
          component: () => import("../views/wallet/ibanpay.vue"),
        },
        {
          path: "services",
          name: "services",
          children: [
            {
              path: "crypto/buy/:symbol",
              name: "buy-crypto",
              component: () => import("../views/services/crypto/buyCrypto.vue"),
            },
            {
              path: "crypto/sell/:symbol",
              name: "sell-crypto",
              component: () => import("../views/services/crypto/sellCrypto.vue"),
            },
            {
              path: "onlinepayment",
              name: "onlinepayment",
              component: () => import("../views/services/onlinepayment/index.vue"),
            },
            {
              path: "onlinepayment/submit",
              name: "onlinepayment-submit",
              component: () => import("../views/services/onlinepayment/submitForm.vue"),
            },
            {
              path: "giftcard",
              name: "giftcard",
              component: () => import("../views/services/giftcard/index.vue"),
            },
            {
              path: "payeer/buy",
              name: "payeer-buy",
              component: () => import("../views/services/payeer/payeerBuy.vue"),
            },
            {
              path: "payeer/sell",
              name: "payeer-sell",
              component: () => import("../views/services/payeer/payeerSell.vue"),
            },
            {
              path: "utopia/buy",
              name: "utopia-buy",
              component: () => import("../views/services/utopia/utopiaBuy.vue"),
            },
            {
              path: "utopia/sell",
              name: "utopia-sell",
              component: () => import("../views/services/utopia/utopiaSell.vue"),
            },
            {
              path: "pm/buy",
              name: "pm-buy",
              component: () => import("../views/services/perfectMoney/pmBuy.vue"),
            },
            {
              path: "pm/sell",
              name: "pm-sell",
              component: () => import("../views/services/perfectMoney/pmSell.vue"),
            },
            {
              path: "giftcard/view/:product_id",
              name: "giftcard-view",
              component: () => import("../views/services/giftcard/productView.vue"),
            },
            {
              path: "bills",
              name: "bills",
              component: () => import("../views/services/ghabzino/index.vue"),
            },
            {
              path: "bill-service/:service",
              name: "bill-service",
              component: () => import("../views/services/ghabzino/billService.vue"),
            },

            {
              path: "car-violation",
              name: "car-violation",
              children: [
                {
                  path: "index",
                  name: "car-violation-index",
                  component: () => import("../views/services/ghabzino/car/index.vue"),
                },
                {
                  path: "plaque",
                  name: "plaque-selector",
                  component: () => import("../views/services/ghabzino/car/plaque.vue"),
                },
                {
                  path: "roles",
                  name: "car-violation-roles",
                  component: () => import("../views/services/ghabzino/car/roles.vue"),
                },
                {
                  path: "violation-all",
                  name: "car-violation-viewall",
                  component: () => import("../views/services/ghabzino/car/violationAll.vue"),
                },
                {
                  path: "violation-details",
                  name: "car-violation-viewdetails",
                  component: () => import("../views/services/ghabzino/car/violationDetails.vue"),
                },
              ],
            },
            {
              path: "referral",
              name: "referral",
              component: () => import("../views/services/referral/index.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/test",
      name: "test",
      component: () => import("../views/test.vue"),
    },
    {
      path: "/testcallback",
      name: "testcallback",
      component: () => import("../views/testcallback.vue"),
    },
    {
      path: "/auth",
      name: "auth-index",
      meta: { layout: authLayout1 },
      component: () => import("../views/auth/index.vue"),
    },
    {
      path: "/auth/login",
      name: "auth-login",
      meta: { layout: authLayout2 },
      component: () => import("../views/auth/login.vue"),
    },
    {
      path: "/auth/register",
      name: "auth-register",
      meta: { layout: authLayout2 },
      component: () => import("../views/auth/register.vue"),
    },
    {
      path: "/auth/register-final",
      name: "auth-register-final",
      meta: { layout: authLayout2 },
      component: () => import("../views/auth/registerFinal.vue"),
    },
    {
      path: "/auth/forget-password",
      name: "auth-forgetpassword",
      meta: { layout: authLayout2 },
      component: () => import("../views/auth/forget_password.vue"),
    },
    {
      path: "/auth/login-verification",
      name: "auth-login-verify",
      meta: { layout: authLayout2 },
      component: () => import("../components/embeded/loginTwoStepVerification.vue"),
    },
  ],
});
router.beforeEach(async (to, from) => {
  const authStore = useUserTokenStore();
  //alert(from.name);
  if(to.name=='wallet-callback' && from.name === 'order-history-details'){
    return false;
  }
  else if(from.name === 'home' && to.name === 'home'){
    return false;
  }
  else if (to.name === 'wallet-callback'){
    return true;
  }
  else if(from.name ==='home' && to.name === 'auth-login-verify' && authStore.isLogined()){
    return false;
  }
  else {
    if (to.meta.requiresAuth) {
      window.scrollTo(0, 0);
      if (authStore.isLogined()) {
        return true;
      } else {
        return {name: "auth-index"};
      }
    }
  }
  return true;
});

App.addListener('appUrlOpen', function (event) {
  const slug = event.url.split('app.mehranbit.com').pop();
  const url = new URL(event.url);
  const queryParams = url.searchParams;
  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
      query: Object.fromEntries(queryParams.entries()),
    });
  }
});
export default router;
