import { Transactiontype } from "@/types/enums/TransactionEnums";
import { MehranBitDepositState } from "@/types/signed/logics";
import { TransactionMode } from "@/types/enums/Transaction";

const i18nLangs_fa = {
  home: "خانه",
  phoneNumberInvalid: "فرمت تلفن وارد شده غلط است",
  fields_required: "لطفا همه فیلد هارا پر کنید",
  tetherPurchaseRate: "نرخ خرید تتر :",
  tetherSalesRate: "نرخ فروش تتر :",
  currency: "تومان",
  currencyS: " تومان ",
  homeInfoText: " اینستاگرام مهران بیت، 10.000 نفری شد!!! ",
  completeAuth: "تکمیل احراز هویت",
  gotoLv2: "ارتقا به سطح 2",
  gotoLv3: "ارتقا به سطح 3",
  levelView: "مشاهده سطح",
  services: "خدمات",
  exchangePerfectMoney: "مبادله پرفکت مانی",
  buyingSellingTether: "خرید و فروش تتر",
  digitalCurrencyExchange: "مبادله ارز های دیجیتال",
  payingBills: "پرداخت قبوض",
  foreignCurrencyPaymentOrder: "سفارش پرداخت ارزی",
  buyGiftCard: "خرید گیفت کارت",
  earnMoney: "کسب درآمد",
  orderRecords: "سوابق سفارشات",
  managementBankAccounts: "مدیریت حساب های بانکی",
  supportTickets: "پشتیبانی (تیکت ها)",
  addressManagementWallets: "مدیریت آدرس کیف پول ها",
  loginStatusManagement: "مدیریت وضعیت ورود",
  warningAlertText: "عکس دست نویس شما رد شده",
  view: "(مشاهده)",
  wallet: "کیف پول",
  buyAndSell: "خرید و فروش",
  market: "بازار",
  user: "کاربری",
  startAuth: "شروع احراز هویت",
  authDes: " در فرایند احراز هویت مهران بیت، به موارد زیر نیاز خواهید داشت:",
  authTime: "کمتر از 5 دقیقه زمان",
  originalIdentityDocuments: "اصل مدارک هویتی",
  smartNationalCardOr: "کارت ملی هوشمند یا",
  nationalCardTrackingCodeAndBirthCertificate: "کد رهگیری کارت ملی هوشمند + شناسنامه",
  selfieVideo: "ویدیو سلفی",
  startAuthProcess: "شروع فرایند احراز هویت",
  confirmContantInfo: "تایید ایمیل",
  please: "لطفا",
  landlineNumber: "شماره تماس ثابت ",
  enterYourselfCarefully: "خود را با دقت وارد کنید.",
  sendCode: "ارسال کد",
  submitGoogleCode: "وارد کردن کد",
  landlineNumberDes:
      "ابتدا شماره تلفن ثابت خود را در کادر بالا وارد نموده و بر روی دکمه ارسال کد کلیک کنید. سپس ربات سایت با تلفن ثابت شما تماس می گیرد و یک کد عددی را برایتان می خواند. کدی که ربات ما برایتان پشت تلفن می خواند را در باکس مربوطه وارد نمایید .در صورتی که کد تایید را درست وارد کرده باشید، شماره تلفن ثابت شما بصورت خودکار تایید می گردد.",
  landlineNumberDes2: "در صورتی که خودتان تلفن ثابت ندارید میتوانید از تلفن ثابت دوستان و آشنایان خود استفاده کنید.",
  codeSent: "  کد ارسال شده به",
  confirmCode: " تایید کد",
  pleaseEnterEmail: '  لطفا <span class="fw-bold">ایمیل</span> خود را با دقت وارد کنید.',
  enterEmailDes: "در صورتی که ایمیلی در اینباکس خود دریافت نکردید لطفا پوشه اسپم خود را چک نمایید.",
  confirmInforAndNextStep: " تایید اطلاعات و مرحله بعد",
  confirmedInfo: " اطلاعات تماس  شما تایید شده است",
  confirmedEmail:'ایمیل شما با موفقیت تایید شد',
  continueAuth: " ادامه احراز هویت",
  sendAgainCode: "ارسال مجدد کد",
  endAuth: "پایان احراز هویت",
  userNameSample: "مهران خرمی عزیز",
  successfullyReceivedInfo: " اطلاعات شما با موفقیت دریافت شد",
  successfullyReceivedInfoDes: "اطلاعات شما تا 2 روز کاری بررسی و نتیجه از طریق پیامک به شما اطلاع خواهد شد",
  trackingCode: "کد پیگیری",
  loginToProgram: "ورود به برنامه",
  verificationIdentityInfo: "تایید اطلاعات هویتی",
  uploadVideoSelfi:'بارگذاری ویدیو سلفی',
  selectOptionsBelow: "لطفا یکی از گزینه های زیر را انتخاب کنید",
  haveNewBirthCertificate: "  شناسنامه جدید دارم",
  haveOldBirthCertificate: "  شناسنامه قدیمی دارم",
  firstLastName: "نام و نام خانوادگی",
  fatherName: "نام پدر",
  dateOfBirth: "تاریخ تولد",
  nationalCode: "کد ملی",
  state: "استان",
  select: "انتخاب کنید",
  city: "شهر",
  tehran: "تهران",
  tabriz: "تبریز",
  shiraz: "شیراز",
  esfahan: "اصفهان",
  address: "آدرس",
  postalCode: "کدپستی",
  completeInfo: "لطفا اطلاعات را کامل کنید",
  invalidPostalCode:'کد پستی وارد شده اشتباه است',
  enterOnlyNumbers: "لطفا فقط عدد وارد کنید",
  fixedNumberNotCorrect: "شماره ثابت صحیح نیست",
  emailNotValid: "ایمیل صحیح نیست",
  enteredCodeNotCorrect: "کد وارد شده صحیح نمی‌باشد",
  confirmed: "تایید شد",
  addressPlaceHolder: "تهران خیابان شریعتی ....",
  haveNationalSmartCard: "کارت ملی هوشمند دارم",
  notHaveNationalSmartCard: "کارت ملی هوشمند ندارم",
  identification: "شناسایی هویت",
  sayFollowingTextOutLoud: "لطفا متن زیر را با صدای بلند و رسا بیان کنید:",
  recordVideoSayText: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.",
  sendVideo: "ارسال ویدیو",
  sendVideoDes: "درصورت درست بودن موارد زیر، ویدیو خود را ارسال کنید",
  soundClear: " صدا واضح ورسا است",
  faceInFrontTheCamera: "چهره مقابل دوربین است",
  lightSuitable: "نور مناسب است",
  check: "بررسی",
  confirmSend: "تایید و ارسال",
  reRecording: "ضبط مجدد",
  sendSelfieVideo: "ارسال ویدیوی سلفی",
  viewSampleVideo: "مشاهده ویدیو نمونه",
  startRecordingSelfieVideo: "شروع ضبط ویدیوی سلفی",
  documentsHaveBeenSent: " مدارک شما با موفقیت ارسال شد ",
  uploadNewBirthCertificateImg: "بارگذاری تصویر صفحه اصلی شناسنامه جدید",
  uploadUpto7MB: "آپلود( تا 7 مگابایت)",
  nationalCardTrackingCode: " کد رهگیری روی رسید کارت ملی هوشمند",
  sendDoc: "ارسال مدارک",
  errorLoadingPhoto: " خطای بارگذاری عکس",
  errorLoadingPhotoDes: "حجم عکس بالای ۷ مگابایت است عکس بارگذاری شده باید زیر ۷ مگابایت باشد",
  close: "بستن",
  enterTrackingCode: "لطفا کد رهگیری را وارد کنید",
  pleaseUploadImg: "لطفا تصویر خواسته شده را بارگذاری کنید",
  continue: "ادامه",
  desShort:'توضیحات',
  uploadOldBirthCertificateImg: "بارگذاری تصویر صفحه اصلی شناسنامه قدیمی",
  uploadBirthCertificateDesPage: "بارگذاری تصویر صفحه توضیحات شناسنامه قدیمی",
  trackingCodeTooltip: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است",
  uploadNationalCardImg: "بارگذاری تصویر روی کارت ملی هوشمند",
  uploadImgOfBackNationalCard: "بارگذاری تصویر پشت کارت ملی هوشمند",
  steps: "مراحل",
  authInMehranBit: " احراز هویت در مهران بیت",
  unDone: "انجام نشده",
  pending: "درحال بررسی",
  accepted: "تایید شده",
  uploadDoc: "بارگذاری مدارک",
  failed: "رد شده",
  waitingBeDone: "در انتظار انجام",
  reasonForRejection: "دلیل رد ({0}) : {1}",
  backHome: "بازگشت به خانه",
  backAuthSteps: "بازگشت به مراحل احراز هویت",
  rule: "قوانین",
  ruleDes1:
      "بر اساس قوانین موجود در بازارهای مالی دنیا و نیز حمایت از قوانین مرتبط با پولشویی، احراز هویت مشتریان به سبب جلوگیری از هرگونه سوء استفاده احتمالی الزامی است. احراز هویت به افرادی که در سایت مهران بیت ثبت نام می‌کنند کمک می‌کند تا با امنیت بیشتر و خیال راحت تری از خدمات سایت استفاده کنند و به معامله بپردازند. لازم به ذکر می باشد اطلاعات ارسالی شما توسط مهران بیت در محل امن نگه داری می شود و در دسترس هیچ شخص یا سازمان دیگری قرار نخواهد گرفت.",
  ruleDes2: "اینجانب {0} متعهد می شوم حساب کاربری و مدارک هویتی و حساب بانکی خود را به افراد غیر و ناشناس اجاره ندهم.",
  ruleDes3: "اینجانب {0} متعهد می شوم که آگاهی کامل نسبت به حساب‌های اجاره‌ای و عواقب کیفری آن دارم.",
  ruleDes4: "اینجانب {0} متعهد می شوم با پول شخصی خود به خرید و فروش ارزهای دیجیتال بپردازم.",
  ruleDes5: "اینجانب {0} متعهد می‌شوم بر روی پروژه‌های قمار و کلاهبرداری پانزی سرمایه گذاری نخواهم کرد و در صورت سرمایه گذاری عواقب آن بر عهده اینجانب می باشد.",
  acceptTermsContinue: " قبول شرایط و ادامه",
  note: "توجه داشته باشید",
  levelUpNoteDes: `
    <p class="my-4 lg-text text-center fw-bold">
    برای ارتقاء به سطح 2 باید اکانت
    <span class="lg-mainColor-text"> Google Authenticator </span> خود را
    فعال نمایید.
  </p>
    `,
  activation: "فعالسازی",
  willDoItLater: " بعدا انجام میدم",
  accomplishedLevel: "احراز شده (سطح {0})",
  notVerified: "احراز نشده",
  levelupTo3: "ارتقاء به سطح ۳",
  inviteFriends: "دعوت از دوستان ",
  generateHighMonthlyIncome: "درآمد بالای ماهیانه ایجاد کنید!",
  managementCardsAndBankAccounts: "مدیریت کارت ها و حساب های بانکی",
  addressManagementWallets: "مدیریت آدرس کیف پول ها",
  security: "امنیت",
  orderRecords: " سوابق سفارشات",
  support: "پشتیبانی",
  newMessage: "{0} پیام جدید",
  notifyMessages: "پیام ها",
  systemMessages: "پیام های سیستمی",
  changeLang: "تغییر زبان (به زودی)",
  logout: "خروج",
  logoutSuccessfully: "با موفقیت خارج شدید",
  notFound: "چیزی یافت نشد",
  noTicketFound:'در حال حاضر شما تیکت پشتیبانی ندارید',
  thereISNoMessage:"<br/>در حال حاضر پیامی ندارید",
  historyRewards: " تاریخچه پاداش ها",
  chooseInvitationMethod: "انتخاب روش دعوت",
  registerFriends: "ثبت نام دوستان",
  receiveFees: "دریافت کارمزد به عنوان معرف",
  rewardsReport: "گزارش پاداش ها",
  numberIntroducedFriends: "تعداد دوستان معرفی شده",
  totalAmountOfBonuses: "مجموع مبلغ پاداش های شما",
  invitationMethod1: "از طریق یکی از راه های موجود دوستانتان را به مهران بیت دعوت کنید و از درآمد آن لذت ببرید.",
  invitationMethod2: " در این مرحله دوستانتان می‌بایست روند ثبت نام خود را تکمیل کنند.",
  invitationMethod3: "پاداش شما بعد از هر معامله توسط دوستانتان به صورت آنی به حساب شما واریز خواهد شد.",
  identificationCode: "کد معرف",
  identificationLink: "لینک معرف",
  share: "اشتراک گذاری",
  sharingOnSocialMedias: "اشتراک گذاری در شبکه های اجتماعی",
  inviteFriendsSMSOrEmail: " دعوت از دوستان با پیامک یا ایمیل",
  send: "ارسال",
  yourBonusPercentage: "درصد پاداش شما",
  person: "نفر",
  lastBonusDeposited: "آخرین پاداش واریز شده",
  num: "شماره",
  giftReceived: "هدیه دریافتی",
  dateAndTime: "تاریخ و ساعت",
  viewAllFees: " مشاهده همه کارمزدها",
  copied: "متن کپی شد",
  historyOfReceivingBonuses: "تاریخچه دریافت پاداش ها",
  previous: "قبلی",
  next: "بعدی",
  cardNumberNotRegistered: "شماره کارتی به اسم شما ثبت نشده است. <br /> برای ثبت کارت بانکی بر روی دکمه افزودن کارت جدید کلیک کنید.",
  viewTransactions: "مشاهده تراکنش ها",
  addNewCard: "افزودن کارت جدید",
  cartNumber: "شماره کارت ",
  addNewCardDes: `توجه داشته باشید که شماره کارت وارد شده می بایست به نام
  <span class="mainColor-text">{0}</span>
  باشد در غیر این صورت شماره حساب به سیستم افزوده نمی شود`,
  addCart: "افزودن کارت",
  successfully: "موفقیت",
  addCartSuccessfully: "ثبت کارت جدید شما با موفقیت انجام شد!",
  confirmationReturn: "تایید و بازگشت به برنامه",
  showPmBuyInfo:"مشاهده اطلاعات خرید",
  bank: "بانک",
  accountOwner: "صاحب حساب",
  shebaNum: "شماره شِبا",
  cardNumberIsNotInYourName: "شماره کارت وارد شده، به‌نام شما نمی‌باشد!",
  enterFullCardNumber: "شماره کارت خود را کامل وارد کنید!",
  showNumber: "نمایش تعداد",
  all: "همه",
  lastCheckInTime: "آخرین زمان ورود",
  desktop: "دسکتاپ",
  twoStepVerification: "تایید دو مرحله‌ای",
  twoStepLoginWithSMS: "ورود دو مرحله ای با پیامک",
  twoStepLoginWithEmail: "ورود دو مرحله ای با ایمیل",
  finalApproval: "تایید نهایی",
  disableTwoStepLoginConfirmationEmail: " آیا از غیر فعال کردن ورود دو مرحله ای با ایمیل اطمینان دارید؟",
  optOut: "انصراف",
  confirm: "تایید",
  loginGa: "     ورود دو مرحله ای با گوگل Google authenticator",
  ga: "گوگل اتنتیکیتور",
  gaDes: "در صورت نیاز به تنظیم مجدد Google Authenticator از بخش پشتیبانی با دپارتمان احراز هویت تماس حاصل فرمایید.",
  iRealized: "متوجه شدم",
  activationGa: "فعالسازی ورود دو مرحله‌ای گوگل",
  changePassword: "تغییر رمز عبور",
  changeTelephone:'تغییر شماره موبایل',
  changeEmail:'تغییر ایمیل',
  passwordChanged:'رمز عبور شما با موفقیت تغییر یافت',
  enableTwoStepLoginConfirmationEmail: "آیا از فعال کردن ورود دو مرحله ای با ایمیل اطمینان دارید؟",
  needHelp: "به کمک نیاز داری؟",
  currentPassword: "رمز عبور فعلی",
  newPassword: "رمز عبور جدید",
  repeatPassword: "تکرار رمز عبور",
  atLeast8CharactersDes: "  حداقل 8 کاراکتر داشته باشد.",
  atLeastOneCapitalLetterDes: " حداقل یک حرف بزرگ داشته باشد.",
  atLeastOneSmallLetterDes: "حداقل یک حرف کوچک داشته باشد.",
  atLeastOneDigit: "  حداقل یک رقم داشته باشد.",
  Same: " یکسان بودن رمزهای عبور.",
  QaChangePassword: " آیا از تغییر رمز عبور خود اطمینان دارید؟",
  sendCodeDes: ` لطفا <span class="mainColor-text"> کد ارسال شده </span> به شماره {0}
  موبایل <span id="phone" dir="ltr" class="d-inline-block"> </span> را
  وارد کنید`,
  changePasswordSuccess: "تغییر رمز عبور شما با موفقیت انجام شد!",
  InstallGaDes: "از طریق گزینه‌‌ی زیر اپلیکیشن مناسب گوشی خود را دانلود و نصب کنید",
  downloadAndrpid: " دانلود نسخه اندروید",
  downloadIOSVersion: "دانلود نسخه آی او اس",
  submitCode: "ثبت کد",
  submitCodeDes: " کد زیر را کپی کنید و در گوگل اتینتیکیتور وارد کنید",
  copyCode: "کپی",
  backup: "پشتیبان گیری",
  backupDes: "   در صورت مفقودی موبایل با این کد امکان بازیابی خواهید داشت",
  rememberCodeAbove: "کد بالا را یاداشت و در محلی مطمئن نگه داری نمایید",
  finalCodeRegistration: "ثبت نهایی کد",
  enterCodeDes: ` لطفا کد ارسال شده به شماره موبایل {number} را وارد کنید`,
  previousStep: " مرحله قبل",
  nextStep: " مرحله بعد",
  twoStepLoginEnabled: "ورود دو مرحله ای شما با موفقیت فعال شد",
  ga6digit: " کد ۶ رقمی گوگل را وارد کنید:",
  finalRegistration: "ثبت نهایی",
  loginHistory: "تاریخچه ورود",
  loginHistoryDes: "در اینجا میتوانید لیست دستگاه ها و آی پی هایی که وارد حساب کاربری شما شده اند را مشاهده و مدیریت نمایید.",
  loginLogoutHistory: "تاریخچه ورود و خروج",
  activeDevice: "دستگاه های فعال",
  chromeBrowser: " مرورگر وب (گوگل کروم)",
  currentDevice: "دستگاه کنونی",
  logoutQa: " آیا از خروج این دستگاه اطمینان دارید؟",
  logoutedDes: "کاربر با ای پی ۱۹۲.۱۶۸.۱.۱۱۹ حذف شد",
  createNewTicket: " ایجاد تیکت جدید",
  viewAllTickets: "مشاهده همه تیکت ها",
  telephoneSupport: "پشتیبانی تلفنی",
  faQ: " سوالات متداول",
  chatOnline: " چت آنلاین",
  chatOnlineWait: " چت آنلاین - کمی صبر کنید",
  whatsappSupport: " پشتیبانی واتس‌اپی",
  sendFeedback: "ارسال فیدبک",
  allTicket: "همه تیکت ها",
  lorem: "لورم ایپسوم متن ساختگی با تولید سادگی",
  answered: "پاسخ داده شده",
  waiting: "در انتظار",
  closed: "بسته شده",
  newTicketRegistration: "ثبت تیکت جدید",
  loaded: "بارگذاری شده",
  cantUploadMoreImg: " بیشتر از ۲ تا عکس نمی توانید آپلود کنید",
  sendTicketSuccessfully: "  تیکت شما با موفقیت ارسال شد",
  replayTicketSuccessfully: "  پیام شما با موفقیت ارسال شد",
  newTicket: " تیکت جدید",
  topic: "موضوع",
  description: "توضیحات",

  fileUpload: " بارگذاری فایل",
  uploadImgRule: "(فقط عکس png,jpg) (حداکثر حجم مجاز فایل: 7mb)",
  department: "دپارتمان",
  writeYourDes: "توضیحات خود را بنویسید",
  loremLg:
      "لورم ایپسوم متن ساختگی با تولید سادگی لورم ایپسوم متن ساختگی با تولید سادگی لورم ایپسوم متن ساختگی با تولید سادگی لورم ایپسوم متن ساختگی با تولید سادگی لورم ایپسوم متن ساختگی با تولید سادگی",
  accounting: "حسابداری",
  awaitingReview: "درانتظار بررسی",
  ticketID: "شناسه تیکت: {0}",
  userLevelSample: " محمدعلی خرمی(کاربر سطح یک)",
  closetTicketQa: " آیا از بستن این تیکت اطمینان دارید؟",
  sendMessageQa: " آیا از ارسال این پیام اطمینان دارید؟",
  sendFileQa: " آیا از ارسال این فایل اطمینان دارید؟",
  showAttachedFile: "نمایش فایل پیوست",
  allMessages: "همه پیام ها",
  privateMessage: " پیام های خصوصی",
  read: " خوانده شده ها",
  unread: " خوانده نشده ها",
  messageSample: "احراز هویت حساب کاربری شما تایید شد.",
  hour: "ساعت",
  sendDate: "تاریخ ارسال",
  btc: "بیت کوین",
  myTrustWallet: "کیف پول تراست ولت خودم :)",
  etc: "اتریوم",
  etcAV: "اتریوم اتمیک ولت",
  addNewWallet: "افزودن کیف پول ارزی",
  deleteWalletAddressQa: `  آیا از حذف کیف پول
  اطمینان دارید؟`,
  selectCurrencyType: "نوع ارز را انتخاب کنید",
  selectYourCurrency: "ارز خود را انتخاب کنید",
  networkType: "نوع شبکه",
  enterWalletTitle: "عنوان کیف پول را وارد کنید",
  enterWalletAddress: "آدرس ولت را وارد کنید",
  enterMemoTag: "تگ/ممو را وارد کنید ",
  addressRegistration: "ثبت آدرس",
  search: "جستجو کنید",
  ltc: "لایت‌کوین",
  dog: " دوج‌کوین",
  shiba: "شیبا",
  registeredAddressSuccessfully: " آدرس با موفقیت ثبت شد",
  currencySearchBoxPlaceHolder: "جستجو کنید...",
  tether: "تتر",
  eth: "اتریم",
  ada: "کاردانو",
  buy: "خرید",
  sell: "فروش",
  pfMoney: "پرفکت مانی",
  sellCurrency: "فروش ارز",
  records: "سوابق",
  youPay: "پرداخت می‌کنید",
  receive: "دریافت می‌کنید",
  ccyUnitPrice: "قیمت هر واحد {0}:",
  dailySalesLimit: "محدودیت فروش روزانه شما :",
  dailySalesLimitInfo: "{0} تومان از {1} تومان پر شده",
  sellCurrencyDes: "مبلغ تومانی اعلام شده تقریبی می باشد و با توجه به متغیر بودن نرخ ارز ها , در لحظه ای که شما ارز را به ولت اعلام شده واریز کردید نرخ دقیق محاسبه و کیف پول ریالی شما شارژ میگردد",
  finalOrderRegistration: "ثبت نهایی سفارش",
  yourWalletAddress: "آدرس ولت شما",
  importantWarning: "هشدار مهم (مشاهده)",
  showQRcode: "نمایش QR-Code",
  sellCurrencyDes: "محمدعلی خرمی عزیز ، لطفا مقدار 2 تتر را به آدرس ولت اعلام شده واریز نمایید. پس از تایید (confirm) واریز تتر ، مبلغ 102,800 تومان ( تقریبی ) کیف پول ریالی شما شارژ خواهد شد .",
  updated: "به روز رسانی شد",
  selectnetworkType: "لطفا شبکه مورد نظر خود را انتخاب نمایید.",
  copy: "کپی",

  sellCurrencyDes1: " لطفا مقدار {0} {1} را به آدرس ولت اعلام شده واریز نمایید. پس از تایید (confirm) واریز {1} ، مبلغ {2} تومان ( تقریبی ) کیف پول ریالی شما شارژ خواهد شد .",
  sellCurrencyDes2: "لطفا در هنگام واریز به آدرس ولت دقت نمایید . در صورت واریز اشتباه مسئولیتی متوجه ما نخواهد بود.",
  scanQrcode: "میتوانید کیو آرکد بالا را اسکن کنید و آدرس ولت خود را به صورت اتوماتیک وارد کنید",
  save: "ذخیره کردن ",
  shareIn: "اشتراک گذاری مطلب در :",
  transactionAmount: "مبلغ تراکنش",
  status: "وضعیت",
  details: "جزئیات",
  deposited: "واریز شده ",
  success: "موفق ",
  successquaeed: "در صف پرداخت",
  unsuccessful: "نا موفق ",
  id: "شناسه",
  numberOfCurrencies: "تعداد ارز ها",
  totalAmount: "مبلغ کل تومانی",
  transactionCode: "کدتراکنش",
  orderStatus: "وضعیت سفارش",
  important: "هشدار مهم",
  walletAlert:
      "با توجه به حساسیت مجموعه‌های قانونگذار بین المللی بر فعالیت صرافی‌های ایرانی که دارای کیف پول ارزی هستند نظیر نوبیتکس، والکس، اکسکوینو و ... خواهشمندیم از هرگونه برداشت و واریز از این گونه صرافی‌ها به مهران بیت و برعکس به جهت حفظ دارایی خود جلوگیری کنید. در صورت انتقال از صرافی‌ها مذکور به مهران بیت و یا از مهران بیت به صرافی‌های مذکور مسئولیت از دست رفتن دارایی به عهده شماست و مهران بیت هیچ مسئولیتی در قبال بلوکه شدن دارایی ارزی شما ندارد.",
  dontShowItAnymore: "دیگه نشون نده",
  transactionSuccessfully: "تراکنش شما با موفقیت انجام شد",
  sellingPerfectMoney: "فروش پرفکت مانی",
  pfMoney: "پرفکت مانی",
  pfVoucher: "وچر پرفکت مانی",
  giveDollars: "دلار می دهید",
  dollarAmount: "مبلغ دلاری ",
  getTomans: "تومان می‌گیرید",
  amountOfToman: "مبلغ تومانی ",
  dailyPurchaseLimit: "محدودیت خرید روزانه :",
  sellingRateEachPerfectMoney: " نرخ فروش هر پرفکت مانی:",
  voucherNumber: "شماره وچر ( E-Voucher ):",
  voucherActivationCode: "کد فعالسازی وچر ( Activation Code ):",
  sellPerfectMoneyQa: " آیا از فروش  پرفکت مانی/وچر خود مطمئن هستید؟",
  sellingPerfectMoneyDes: "مبلغ {0} تومان در کیف پول ریالی شما شارژ شد",
  invoice: "پیش فاکتور",
  invoiceID: "شناسه فاکتور",
  orderType: "نوع سفارش",
  buyPerfectMoneyVoucher: " خرید وچر پرفکت مانی",
  invoiceDate: "تاریخ فاکتور",
  dollarPrice: "قیمت دلاری",
  walletAddress: "آدرس ولت",
  unitPrice: "قیمت واحد",
  unitPriceToman: "قیمت واحد (تومان)",
  reqAmount: "مقدار درخواستی",
  walletAddress: "آدرس کیف پول",
  memoTag: "تگ / ممو/ کامنت",
  memoTagForce: 'تگ / ممو/ کامنت <span style="color:red">( اجباری ) </span>',
  networkTypeFee: "کارمزد شبکه",
  payable: "قابل پرداخت",
  updatePriceText: "کاربر گرامی با توجه به نوسانات بالای ارز های دیجیتال مبلغ قابل پرداخت هر 30 ثانیه به روز میگردد",
  usingWalletCharging: " استفاده از شارژ کیف پول",
  currentBalance: " موجودی فعلی ",
  onlinePayment: " پرداخت آنلاین",
  sellVoucherPerfectMoneyQa: " آیا از فروش 1 دلار پرفکت مانی/وچر مطمئن هستید؟",
  buyCurrency: "خرید ارز",
  buyPerfectMoney: "خرید پرفکت مانی",
  doneSuccessfully: "با موفقیت انجام شد",
  remainingTime: "زمان باقیمانده",
  timerISover: "زمان تمام شد",
  paymentDeadlinePassed: "مهلت پرداخت تمام شد",
  paymentStatus: "وضعیت پرداخت",
  invoicePaidSuccessfully: " فاکتور با موفقیت پرداخت شد",
  invoicePaidText: ` خرید پرفکت مانی شما به مبلغ ۲،۰۰۰،۰۰۰ تومان به تعداد ۳۰ دلار انجام شد
. و به زودی به آدرس ولت
<span class="lg-mainColor-text mx-1"> u526636637 </span>
واریز خواهد شد`,
  factorNumber: "شماره فاکتور",
  price: "مبلغ",
  dollar: "دلار",
  perfectMoneyInventory: "موجودی پرفکت مانی",
  getDollars: " دلار می‌گیرید",
  youPayToman: "تومان می دهید",
  purchaseRateEachPerfectMoney: " نرخ خرید هر پرفکت مانی:",
  perfectMoneyWalletAddress: " آدرس کیف پول پرفکت مانی",
  recordOrder: "ثبت سفارش",
  bill: "صورتحساب",
  currencyType: "نوع ارز",
  numberCurrencies: "تعداد ارز",
  amountEachCurrency: "مبلغ هر ارز",
  finalAmount: "مبلغ نهایی",
  currentInventorySite: "موجودی فعلی قابل خرید از سایت :",
  currencyWalletAddress: "آدرس کیف پول ارزی",

  enterOnlyEnglishLetters: "لطفا فقط حروف انگلیسی وارد کنید",
  chooseFromWallets: "انتخاب از کیف پول های من",
  selectWallet: "انتخاب کیف پول",
  memoTagAddress: "آدرس ممو / تگ",
  exchangeRate: "نرخ تبادل",
  exchangeType: "نوع تبادل",
  buyTether: "خرید تتر",
  networkPostingFee: "کارمزد ارسال شبکه",
  toman: "تومان",
  coinDeliveryTime: "زمان تحویل کوین",
  tradeFeeTitle:'کارمزد ترید',
  instantDeliveryAfterDeposit: "تحویل آنی(بعد واریز) ",
  minWithdrawnTether: "حداقل میزان تتر قابل برداشت",
  amountPayable: "مبلغ قابل پرداخت:",
  toChoose: "انتخاب کردن",
  chosed: "انتخاب شد",
  foreignCurrencyPaymentOrderProformaInvoice: "پیش فاکتور سفارش پرداخت ارزی",
  finalPaymentQa: "  آیا از پرداخت مبلغ {0} تومان از کیف پول خود اطمینان دارید؟",
  foreignCurrencyPayment: "پرداخت ارزی",
  site: "سایت",
  missionAccomplished: "عملیات با موفقیت انجام شد!",
  missionAccomplishedByCrypto: "مقدار {0} {1} به زودی به ادرس ولت {2} ارسال میگردد",
  paymentMethod: "روش پرداخت",
  paymentPerfectMoney: "پرداخت با پرفکت مانی",
  paymentWith: "پرداخت با {0}",
  paymentFromWallet: "پرداخت از کیف پول",
  newOrder: "سفارش جدید",
  ordersBeingProcessed: "سفارش های درحال پردازش",
  paymentDes: "انجام پرداخت‌های دلاری شما از طریق پی پال، پرفکت مانی ، وب مانی و ارز های دیجیتال با استفاده از سرویس پرداخت ارزی مهران بیت",
  paymentByPayPal: "پرداخت با پی پال",
  payWithBitcoin: "پرداخت با بیت کوین",
  otherSite: "سایت های دیگر",
  registrationInternationalExams: "ثبت نام آزمون های بین المللی",
  purchaseForeignSites: "خرید از سایت های خارجی",
  bookingForeignHotelTickets: "رزرو بلیط هتل های خارجی",
  internetAddress: " آدرس اینترنتی",
  usernameInSite: " نام کاربری در سایت",
  yourPasswordInsite: "رمز عبور شما در سایت",
  descriptionForeignCurrencyPaymentOrder: " توضیحات: (چنانچه پرداخت شما نیاز به توضیحات دارد اینجا عنوان کنید)",
  uploadPhotoOptional: "بارگذاری عکس(اختیاری)",
  performPaymentOperations: "انجام عملیات پرداخت با:",
  dollarAmountOrder: "مبلغ دلاری سفارش",
  giftCartQa: "آیا از خرید گیفت کارت {0} به مبلغ {1} دلار مطمئن هستید ؟ (پس از تایید مبلغ {2} از کیف پول ریالی شما کسر خواهد شد)",
  registeredOrderText: "سفارش شما ثبت شد",
  giftCart: "گیفت کارت",
  regionGiftCard: "ریجن گیفت کارت:",
  globalActivationFeature: " قابلیت فعال سازی جهانی:",
  dosentHave: "ندارد",
  doseHave: "دارد",
  reqPricet: "مبلغ درخواستی",
  yourGiftCartInfo: " اطلاعات گیفت کارت شما",
  giftCartCode: "کد گیفت کارت",
  sorryNotFound: "متاسفانه چیزی یافت نشد",
  searchByName: "جست و جو بر اساس نام ...",
  allCountries: "همه کشورها",
  bestSellingProduct: "محصولات پرفروش",
  allKindsOFgiftCardBrands: "انواع برند های گیفت کارت",
  comparisonOFlevels: "مقایسه سطح ها",
  currentLevel: " سطح فعلی شما",
  level3: "سطح سه",
  notCompleted: "تکمیل نشده",
  levelUp3: "ارتقا به سطح سه",
  level2: "سطح دو",
  level1: "سطح یک",
  dailySalesCap: "سقف فروش روزانه:",
  dailyWithdrawalLimit: "سقف برداشت روزانه:",
  verificationStatus: "وضعیت احراز :",
  verified: "  احراز شده",
  userLevel: "سطح کاربر",
  yourDailyPurchaseBalanceLimit: " سقف مانده خرید روزانه شما:",
  yourDailyBalanceLimit: "سقف مانده فروش روزانه شما:",
  yourDailyWithdrawalLimit: "سقف مانده برداشت روزانه شما:",
  yourDailyPurchaseBalanceLimit2: " سقف خرید روزانه شما:",
  yourDailyBalanceLimit2: "سقف فروش روزانه شما:",
  yourDailyWithdrawalLimit2: "سقف برداشت روزانه شما:",
  reasonRejection: "دلیل رد",
  reasonRejectionSample: "تست طولانی دلیل رد در این مجموعه",
  levelup: "ارتقا به سطح {0}",
  upload: "بارگذاری کردن",
  levelup2Des1: "مدارک ارسالی شما توسط مهران بیت در محلی امن نگهداری شده و در دسترس هیچ شخص یا سازمان دیگری قرار نخواهد گرفت.",

  handwrittenPhoto: "عکس دست نویس",
  levelup2Des2: "لطفا متن زیر را بر روی یک برگه به صورت واضح و خوانا یادداشت نموده و تصویر آن را برای ما ارسال نمایید",
  levelup2Des3:
      "اینجانب {0} با کد ملی {1} درخواست ارتقا پنل کاربری خود به سطح دو را دارم. متعهد می شوم حساب کاربری و بانکی خود را به افراد غیر اجاره ندهم و کلیه مسئولیت این حساب کاربری با اینجانب می باشد.",
  phoneNumber: "شماره موبایل",
  signature: "امضاء",
  viewSampleDocument: "مشاهده مدرک نمونه",
  uploadHandwrittenPhoto: "بارگذاری عکس دست نویس:",
  selfieWithHandwrittenPhoto: "سلفی با عکس دست نویس و مدرک شناسایی",
  levelup2Des4: "کاغذ دست نویس + کارت ملی خود را رو به روی سینه خود قرار داده و یک عکس واضح بگیرید و ارسال نمایید.",
  levelup2Note1: "نکته 1: در صورتی که کارت ملی ندارید، میتواند این گزینه را با شناسنامه جدید یا پاسپورت یا گواهینامه معتبر جایگزین کنید.",
  uploadSelfie: "  بارگذاری عکس سلفی:",
  levelUp3Des1: "مدارک ارسالی شما توسط مهران بیت در محلی امن نگهداری شده و در دسترس هیچ شخص یا سازمان دیگری قرار نخواهد گرفت.",
  imgCommitmentLetter: "تصویر تعهد نامه",
  levelUp3Des2:
      "کاربر گرامی لطفا فایل تعهد نامه را دانلود و پرینت نمایید. سپس مشخصات خواسته شده در فایل تعهد نامه را به صورت خوانا و مطابق با پروفایل کاربری خود تکمیل نموده، و بعد از امضاء تعهد نامه آن را با کیفیت مناسب اسکن و برای ما ارسال نمایید.",
  downloadCommitmentLetterFile: " دانلود فایل تعهد نامه",
  uploadCommitmentLetter: " بارگذاری تعهد نامه:",
  confirmationImgSignatureCertificate: "تصویر تایید گواهی امضاء",
  levelUp3Des3: "کاربر گرامی شما می بایست با مراجعه به دفتر اسناد رسمی، تایید گواهی امضاء خود را دریافت، و پس از اسکن آن، گواهی امضاء خود را برای ما ارسال نمایید.",
  uploadSignatureCertificateConfirmation: " بارگذاری عکس تایید گواهی امضاء:",
  notSend: "ارسال نشده",
  payElectricityBill: " پرداخت قبض برق",
  nameCurrencyService: "نام ارز/خدمات",
  electricBill: " قبض برق",
  allAmount: "مبلغ کل",
  currencySituation: "وضعیت ریال/ارز",
  paidPort: " ریال/پرداخت شده (درگاه)",
  info: "اطلاعات",
  transactionType: "نوع تراکنش ",
  incrementalCompensation: "افزایشی(پورسانت) ",
  for: "بابت",
  sellBitcoinToId: "فروش بیت کوین به شناسه سفارش ۱۴۷۸۴۹",
  moreInfo: "  اطلاعات بیشتر",
  recordsOfGiftCardOrders: "سوابق سفارشات گیفت کارت",
  recordsOfPerfectMoneyOrders: "سوابق سفارشات پرفکت مانی",
  billId: "شناسه قبض",
  paymentId: " شناسه پرداخت",
  show: "نمایش",
  trackingId: "شناسه تراکنش",
  paymentSureQa: " آیا از پرداخت قبض با مبلغ {0} تومان از کیف پول خود اطمینان دارید؟",
  gobackPage: " بازگشت به صفحه قبلی",
  deletedBillQa: " آیا قصد پاک کردن استعلام قبلی و استعلام قبض جدیدی را دارید ؟",
  cancel: "لغو",
  waterBill: "قبض آب",
  gazBill: " قبض گاز",
  electricBill: "قبض برق",
  landlinePhone: "تلفن ثابت",
  mci: "همراه اول",
  irancel: "ایرانسل",
  violation: "پرداخت خلافی با پلاک",
  payingBillsHistory: " تاریخچه پرداخت قبوض",
  billType: "نوع قبض ",
  vehicleViolation: " خلافی خودرو",
  billHasBeenPaidSuccessfully: "پرداخت قبض شما با موفقیت انجام شد",
  waterBillId: "  شناسه قبض آب",
  gazBillId: "شناسه قبض گاز",
  subCode: "کد اشتراک",
  electricityBillId: "شناسه قبض برق",
  landlineNumberPlaceholder: "محل درج شماره تلفن ثابت (با کد شهر درج کنید )",
  mciPermanent: "شماره (دائمی) همراه اول",
  mciPlaceholder: "محل درج شماره دائمی همراه اول",
  irancelPermanent: "شماره (دائمی) ایرانسل",
  irancelPlaceholder: " محل درج شماره دائمی ایرانسل",
  paymentBillSuccessfully: "قبض شما با موفقیت پرداخت شد",
  cancelled: "لغو شده",
  nationalCodeplaceHolder: "کد ملی مالک پلاک را وارد نمایید",
  ownerPhonePlaceholder: "شماره تلفن همراه مالک پلاک را وارد نمایید",
  enterLicensePlate: "وارد کردن پلاک",
  numberPlates: "شماره پلاک:",
  iran: "ایران",
  disabledPeople: "معلولین",
  ceremonies: "تشریفات",
  enterPlaqueLeft: "پلاک را از سمت چپ وارد نمایید.",
  nationalCodeOwner: " کد ملی مالک : ",
  ownerPhoneNumber: "شماره تلفن همراه مالک :",
  plaqueInfo: "کاربر گرامی ؛ توجه داشته باشید کد ملی و شماره موبایل مالک می بایست به نام صاحب پلاک خودرو باشد",
  generalWrongfulPayment: "پرداخت خلافی کلی",

  billInfo: "اطلاعات قبض",
  totalViolation: "مجموع خلافی:",
  plaque: "پلاک:",
  totalAmountPaid: "مبلغ کل پرداختی:",
  paymentBillQa: ` آیا از پرداخت قبض به مبلغ
<span class="lg-mainColor-text">۴۰۰,۰۰۰ تومان</span>
اطمینان دارید؟`,
  payment: "پرداخت",
  vehicleViolationDetails: " جزییات خلافی خودرو",
  billInfo: "اطلاعات قبض",
  vehicleViolationDetailsDes: "در این بخش اطلاعات قبض استعلامی نمایش داده می شود",
  billDetails: " جزئیات قبض",
  paymentSuccessfullyPenaltyDes: " جریمه ۱ با موفقیت پرداخت شد",
  payAnotherFine: " پرداخت جریمه دیگری",
  viewPaidBills: "مشاهده قبوض پرداخت شده",
  penalty: "جریمه",
  payAllPenalty: "پرداخت تمامی جریمه ها",
  penaltyDetail: "جزئیات جریمه",
  penaltyTitle: "عنوان جریمه",
  penaltyDes: "عدم پرداخت عوارض مقرر در آزاد راه های برای خودرو های سواری,وانت, مینی بوس و کامیونت",
  penaltyLocation: "محل جریمه:",
  penaltyLocationSample: "کرج گرمدره",
  type: "نوع",
  camera: "دوربین",
  allPenalty: "تمامی جریمه ها",
  termsAndConditions: " قوانین و مقررات",

  termsitems: `<li>هزینه استعلام خلافی برای هر پلاک، 5,200 تومان.</li>
<li>هزینه استعلام از کیف پول ریالی شما کسر می شود.</li>
<li>
  امکان استفاده از این مبلغ برای پرداخت صورتحساب خلافی وجود ندارد.
</li>
<li>
  این مبلغ از سوی پلیس ناجی دریافت می‌شود و امکان بازگشت وجه پرداختی
  وجود ندارد.
</li>
<li>
  نمایش جرائم وسایل نقلیه دارای شکایت امکان‌پذیر نیست. در صورت ثبت
  شکایت برای وسیله نقلیه شما، جریمه‌ای نمایش داده نمی‌شود.
</li>
<li>
  در صورت پرداخت ناموفق، مبلغ کسرشده حداکثر تا ۷۲ ساعت به حساب شما
  بازگردانده می‌شود.
</li>
<li>
  نتایج پرداخت های شما در بخش سوابق سفارش ذخیره شده و در هر زمان قابل
  دسترسی است.
</li>`,

  acceptTermsDes: " با کلیک بر روی دکمه زیر، شرایط و قوانین ذکر شده را میپذیرم.",
  showBill: " نمایش قبض",
  confirmBill: "تایید قبض",
  showBillDes: "هزینه استعلام خلافی 5,200 تومان می باشد با کلید بر روی دکمه تایید این مبلغ از کیف پول ریالی شما کسر می شود",
  inquireDetailsViolation: " استعلام جزئیات خلافی با پلاک",
  inquireDetailsViolationItems: `  <li>هزینه: 5,200 تومان</li>
<li>پرداخت با تسویه آنی</li>
<li>نمایش جزئیات کامل خلافی</li>
<li>نمایش عکس تخلف</li>`,
  inquireTotalViolationItems: "استعلام مجموع خلافی با پلاک",
  inquireTotalViolationItemsDes: ` <li>هزینه: 5,200 تومان</li>
<li>پرداخت با تسویه آنی</li>
<li>بدون نمایش جزئیات</li>`,
  enterCompletePlaque: "لطفا پلاک را کامل وارد کنید",
  nationalCodeIsNotCorrect: "کد ملی صحیح نمی باشد",
  enterPhoneNumber: "لطفا شماره همراه خود را وارد کنید",
  mobileNumberIsNotCorrect: "شماره همراه صحیح نیست",
  highestPrice24h: "بالاترین قیمت (24h)",
  lowestPrice24h: "پایین‌ترین قیمت (24h)",
  exchangeRates: " نرخ ارز ها",
  currencyNews: " اخبار ارز ها",
  basedOnTether: "برپایه تتر",
  basedOnToman: " برپایه تومان",
  biggestLoss: "کمترین",
  mostProfit: "بیشترین ",
  solana: "سولانا",
  sortedBy: "مرتب سازی بر اساس:",
  sortedBy2: "مرتب سازی براساس سود:",
  default: "پیشفرض",
  mostVisit: "پربازدید",
  categories: " دسته بندی ها ",
  allCategory: " همه دسته بندی ها",
  newsTitleSample1: "خبر بیت کوین",
  newsTitleSample2: "خبر فلان",
  newsTitleSample3: "خبر تتر",
  timeAgoSample: "12ساعت پیش",
  commentPlaceholder: "نظر خود را درمورد این موضوع بنویسید",
  newsTitle: "عنوان خبر",
  addComment: "افزودن نظر",
  seeAllComment: "  مشاهده همه نظرات",
  allComment: "همه نظرات",
  amountPendingSettlement: "مبلغ در انتظار تسویه",
  moneyIncrease: "افزایش وجه",
  withdrawalRequest: " درخواست برداشت",
  newInventory: "موجودی جدید",
  totalInventory: "موجودی کل",
  transactionHistory: "تاریخچه تراکنشات",
  historyOfWithdrawals: "تاریخچه برداشت ها",
  dateAndTimeReq: "تاریخ و ساعت درخواست",
  destinationBank: "بانک مقصد",
  national: "ملی",
  destinationAccountNumber: "شماره حساب مقصد",
  Destinationnightnumber: "شماره شبا مقصد",
  Destinationcardnumber: "شماره کارت مقصد",
  seeDetailsDeposit: "مشاهده جزئیات واریز ",
  withdrawalReqDes: "درخواست برداشت شما برای بخش حسابداری مهران بیت ارسال شده است.به زودی مبلغ درخواستی به حساب شما واریز خواهد شد",
  notesToUser: "توضیحات برای کاربر",
  dateAndTimeSettlement: "زمان ثبت تسویه",
  approxMoneyTransferTime:'پیش بینی زمان واریز',
  bankTrackingCode: "کد پیگیری بانکی",
  settlementAccountNumber: "شماره حساب تسویه کننده",
  settlementShabaNumber: "شماره شبا مقصد",
  viewDepositImg: "مشاهده تصویر واریزی",
  done: "انجام شده",
  requiredAmount: "مبلغ مورد نیاز:",
  amountPlaceholder: "مبلغ مورد نظر را انتخاب یا وارد کنید",
  bankingPortalFee: "کارمزد درگاه بانکی",
  moneyIncreaseQa: "آیا از افزایش مبلغ 2.000.000 تومان اطمینان دارید؟",
  moneyIncreaseQaDes: "افزایش وجه فقط از طریق کارت های تعریف شده درحساب کاربری، مجاز و امکان پذیر می‌باشد",
  enterRequiredAmount: "لطفا مبلغ مورد نیاز را وارد کنید",
  paymentFailed: "پرداخت ناموفق",
  paymentFailedDes: " سفارش پرداخت نشده یا ناموفق بوده است .",
  returntoMehran: "بازگشت به مهران‌بیت",
  paymentSuccess: "پرداخت موفق",
  paymentSuccessDes: "مبلغ {0} به زودی به حساب انتخابی شما واریز خواهد شد.",
  withdrawalRequest: "درخواست برداشت وجه",
  currentBalance: "موجودی فعلی شما",
  dailyWithdrawalLimit: "محدویت برداشت روزانه :",
  cardNumberIsNotRegistered: "شماره کارتی به اسم شما ثبت نشده برای ثبت کارت بانکی بر روی دکمه افزودن کارت جدید کلیک کنید",
  selectAccount: "انتخاب حساب",
  bankMellat: "بانک ملت",
  bankAccountInfo: "  میتوانید هر یک از حساب های بانکی تایید شده خود را برای دریافت وجه انتخاب کنید.",
  createWithdrawalRequest: " ایجاد درخواست برداشت",
  createWithdrawalRequestDes: "آیا تایید می کنید درخواست برداشت شما با مشخصات زیر به شماره کارت {0} واریز گردد ؟",
  createWithdrawalRequestDes2:'مبلغ کل برداشت : {0} تومان',
  createWithdrawalRequestDes3:'کارمزد بانکی واریز : {0} تومان',
  createWithdrawalRequestDes4:'مبلغ خالص دریافتی : {0} تومان',
  approximateTimeReceiptPayment: "زمان تقریبی دریافت وجه",
  settlementTiming: "زمان بندی تسویه",
  settlementToAyandeBank: "تسویه به بانک آینده",
  settlementToOtherBanks: " تسویه به سایر بانک ها بر اساس سیکل تسویه پایا بانک مرکزی صورت میگیرد:",
  firstCycle: "سیکل اول = حدود 4:00 بامداد",
  secondCycle: "سیکل دوم = حدود 10:30 قبل از ظهر",
  thirdCycle: "سیکل سوم = حدود 13:45 بعد از ظهر",
  fourthCycle: "سیکل چهارم = حدود 18:45 بعد از ظهر",
  allInventory: "کل موجودی",
  withdrawingBelow10NotPossible: "برداشت مبلغ زیر  ۱۰,۰۰۰ تومان ممکن نیست! ",
  selectAccountNumber: "لطفا شماره حساب خود را انتخاب کنید",
  serviceFee: "کارمزد خدمات",
  loginSignup: "ورود/ ثبت نام",
  login: "ورود",
  register: "ثبت نام",
  ignore: "رد شدن",
  loginInto: ` شما با شماره موبایل<span id="phonenumber"></span> در حال ورود به حساب
  کاربری خود هستید...`,
  forgotPassword: " فراموشی رمز عبور",
  password: "رمز عبور",
  areaCode: "(۹۸+)",
  enterPhoneNumberInBox: "لطفا شماره موبایل خود را در کادر زیر وارد کنید",
  dontHavAccountQa: "حساب کاربری ندارید ؟",
  create: "ایجاد کنید",
  didYouForgotPassword: " رمز عبور خود را فراموش کردید ؟",
  restore: "بازیابی کنید",
  enterPassword: "لطفا گذرواژه خود را وارد کنید",
  pleaseWait: "لطفا منتظر بمانید",
  otpUserLoginPage: "صفحه ورود کاربر otp",
  pleaseWait: "لطفا منتظر بمانید",
  forgotPasswordDes: "لطفا جهت بازگردانی رمز عبور، شماره موبایل خود را در کادر زیر وارد نمایید",
  enterNationalCode: "کد ملی خود را وارد کنید",
  changePasswordQa: "آیا از تغییر رمز عبور خود اطمینان دارید؟",
  enterYourIDcode: "کد معرف خود را وارد کنید.(اختیاری)",
  sendNewPassword: "رمز جدید شما با موفقیت به شماره موبایل شما پیامک شد",
  retunLoginPage: "بازگشت به صفحه ورود",
  twoStepLogin: "ورود دو مرحله ای",
  selectYourTwoStepLogin: "لطفا متد ورود دو مرحله ای خود را انتخاب نمایید",
  receiveCodeBySMS: "دریافت کد با پیامک",
  receiveCodeByEmail: "دریافت کد با ایمیل",
  loginWithGa: " ورود با Google Authenticator",
  enterGaCode: ` لطفا
<span class="mainColor-text">
کد نمایش داده شده
</span>
در گوگل اتنتیکیتور را وارد کنید`,
  codeSentToEmail: `   لطفا
<span class="mainColor-text">
کد ارسال شده
</span>
به ایمیل example@gmail.com خود را وارد کنید`,
  codeSentToPhone: `   لطفا
<span class="mainColor-text">
کد ارسال شده
</span>
به شماره ${JSON.parse(localStorage.getItem("user"))?.phone} خود را وارد کنید`,
  editPhone: "(ویرایش شماره)",
  mobileNumberMustBeInYourName: " شماره موبایل حتما باید به نام خودتان باشد!",
  birthdayDate: "تاریخ تولد",
  enterBirthdaydate: "لطفا تاریخ تولد خود را وارد کنید",
  uploadImg: "بارگذاری عکس",
  viewTransactionsCart: "مشاهده تراکنشات کارت",
  currency2: "ارزی",
  serviceCurrnecy: "خدمات ارزی",
  deleteWalletAddress: "حذف آدرس کیف پول",
  embdedTwoStepVerificationTitle: "لطفا کد ارسال شده به {0} را وارد کنید",
  wrongVerifyCode: "کد وارد شده اشتباه است",
  warningModal: "خطا",
  BuyRate: "خرید {0} از ما",
  SellRate: "فروش {0} به ما",
  UTOPIA:'یوتوپیا',
  USDT: "تتر",
  PM: "پرفکت مانی",
  PAYEER:"پاییر",
  err: "خطا",
  serviceUnAvailable: "سرویس مورد نظر در‌حال حاضر غیر فعال می باشد",
  requireUpdate:'برای استفاده از این سرویس برنامه مهران بیت را آپدیت کنید',
  pendingIban:'واریز شناسه دار شما 72 ساعت بعد از واریز قابل استفاده خواهد بود',
  authenticates: {
    1: "کارت ملی جلو",
    2: "کارت ملی پشت",
    3: "شناسنامه جدید",
    4: "صفحه اصلی شناسنامه قدیمی",
    5: "صفحه توضیحات شناسنامه قدیمی",
    6: "عکس رهگیری کارت ملی",
    7: "فیلم سلفی",
    8: "عکس سلفی",
    9: "عکس دست نویس",
    10: "تصویر تعهد نامه",
    11: "تایید گواهی امضا",
    12: "آدرس",
  },
  route_title: {
    home: "خانه",
    user_management_index: "کاربری",
    wallet_index: "کیف پول",
    market: "بازار",
    buyandsell: "خرید و فروش",
  },
  authorizationRejected: "رد شده",
  userLevelNum: " سطح کاربر (سطح {0})",
  not_noted: "نوشته نشده",
  userLvUpTo: "ارتقا به سطح {0}",
  endOfAuth: "احراز هویت شده",
  enterCodeSentLine: "کد ارسال شده به شماره تماس ثابت {number} را وارد کنید",
  enterCodeSentEmail: "کد ارسال شده به ایمیل {number} را وارد کنید",
  edit_email: "(ویرایش ایمیل)",
  homeTellHasBeenVerified: " شماره تلفن ثابت شما تایید شد",
  emailHasBeenVerified: " ایمیل شما تایید شد",
  uploadErr: "آپلود فایل ها با مشکل مواجه شده است . میتوانید با VPN و یا بدون VPN مجددا امتحان کنید . در صورت بروز مشکل تکراری ساعاتی بعد امتحان کنید",
  selectCardFiles: "عکس کارت ملی جلو و پشت را انتخاب کنید",
  sendselfieVideo: "ارسال ویدیو سلفی",
  sendselfieVideoAlreadySent: "ویدیو سلفی شما با موفقیت بارگذاری شد.\n" +
      "منتظر بررسی کارشناسان مهران بیت باشید.",
  BirthDayIdCardTrackTooltip: "کد پیگیری شناسنامه جدید",
  install2fa: "نصب Google Authenticator",
  sendsms: "ارسال پیامک",
  levelup2: "ارتقا به سطح دو",
  levelup3: "ارتقا به سطح سه",
  levelup4: "ارتقا کامل",
  date: "تاریخ",
  levelupToVal: "ارتقا به سطح {0}",
  checkcard: "بررسی کارت",
  cardIsIncorrect: "کارت بانکی وارد شده اشتباه است",
  Transactiontype: {
    DEPOSIT: "افزایش",
    WITHDRAW: "برداشت",
    REFERALCODE: "معرف",
  },
  deposit_with_id: "واریز (شناسه :{0})",
  deposit_with_ref: "واریز کد معرف",
  wd_with_ref: "برداشت",
  service: "خدمات",
  notSubmitted: "ثبت نشده",
  currentPassRequired: "رمز فعلی را وارد کنید",
  amount: "مبلغ",
  FactorTypes: {
    BUY_CRYPTO: "خرید ارز دیجیتال",
    SELL_CRYPTO: "فروش ارز دیجیتال",
    BUY_PERFECTMONEY: "خرید پرفکت مانی",
    SELL_PERFECTMONEY: "فروش پرفکت مانی",
    ONLINE_PAYMENT: "پرداخت ارزی",
    GHABZINO: "قبض",
    GIFTCARD: "گیفت کارت",
    BUY_PAYEER:'خرید پاییر',
    SELL_PAYEER:'فروش پاییر',
    BUY_UTOPIA:'خرید یوتوپیا',
    SELL_UTOPIA:'فروش یوتوپیا'
  },
  services_types: {
    0: "پرداخت ارزی",
    1: "خرید ارز",
    2: "فروش ارز",
    3: "خرید پرفکت مانی",
    4: "فروش پرفکت مانی",
    5: "پرداخت قبض",
    6: "پرداخت ارزی",
    7: "گیفت کارت",
    8: "خرید پاییر",
    9: "فروش پاییر",
    10:'خرید یوتوپیا',
    11:'فروش یوتوپیا'
  },
  factorStatus: {
    NOT_PAID: "در انتظار پرداخت",
    PAID_PEINDING: "در حال انجام",
    WITHDRAW: "در حال ارسال",
    SENT: "ارسال شده",
    Receiving_Pending: "در انتظار دریافت",
    Exchanging: "در حال مبادله",
    RialWalletCharged: "انجام شده",
    REJECTED: "رد شده",
    CanceledByUser: "لغو شده",
    GhabzinoPaid: "پرداخت شده",
    GhabzinoFailed: "عملیات ناموفق",
    GhabzinoPending: "در حال پرداخت",
    PerfectMoneyBuyPending: "در انتظار پرداخت",
    PerfectMoneyBuyComplete: "انجام شده",
    PerfectMoneySellComplete: "",
    PerfectMoneyBuyNotPaid: "لغو شده",
    OnlinePaymentPending: "در حال بررسی",
    OnlinePaymentRejectedbyAdmin: "رد شده",
    OnlinePaymentPaidbyAdmin: {
      OnlinePaymentNotPaid: "لغو شده",
      OnlinePlaymentOrdered: "انجام شده",
    },
    GiftCardComplete: "موفق",
    factor_timeout: "لغو شده",
    0: "در انتظار پرداخت",
    1: "در حال انجام",
    2: "در حال ارسال",
    3: "ارسال شده",
    4: "در انتظار دریافت",
    5: "در حال مبادله",
    6: "دریافت شده",
    7: "رد شده",
    8: "لغو شده",
    9: "پرداخت شده",
    10: "عملیات ناموفق",
    11: "در حال پرداخت",
    12: "در انتظار پرداخت",
    13: "انجام شده",
    14: "",
    15: "لغو شده",
    16: "در حال بررسی",
    17: "رد شده",
    18: {
      OnlinePaymentNotPaid: "لغو شده",
      OnlinePlaymentOrdered: "انجام شده",
    },
    19: "موفق",
    20: "لغو شده",
  },
  cryptoNameCount: "نام و تعداد ارز",
  transactionStatus: "وضعیت تراکنش",
  reciver: "گیرنده",
  dollarPriceAtTime: "قیمت ارز در لحظه خرید ( دلاری )",
  tomanPriceAtTime: "قیمت ارز در لحظه خرید",
  totalAmountOrder: "مبلغ کل سفارش",
  txId: "کد تراکنش",
  working: "در حال انجام",
  failedOrrejected: "لغو یا رد شده",
  workingonTask: "در حال انجام عملیات",
  recvied: "دریافت شده",
  receiving: "در حال دریافت",
  completingTransation: "در حال تکمیل تراکنش",
  MehranBitDepositState: {
    0: "دریافت اولین Block",
    1: "در حال دریافت",
    2: "در حال دریافت",
    3: "دریافت شد",
    4: "رهگیری حسابرسی",
    5: "رد شده",
    6: "حساب مسدود شده",
  },
  billtypes: {
    Electric: "برق",
    Water: "آب",
    Gas: "گاز",
    Mobile: "قبض موبایل",
    Driving: "خلافی خودرو",
    Phone: "تلفن ثابت",
  },
  giftcard:'گیفت کارت',
  giftcardId: "شناسه گیفت کارت",
  giftcardName: "نام گیفت کارت",
  giftcardAmount: "مبلغ دلاری گیفت کارت",
  region: "ریجن",
  giftcardnumber: "شماره گیفت کارت",
  desPMwallet: "کیف پول مقصد",
  paymentWays: "نحوه پرداخت",
  opUsername: "نام کاربری",
  usdcalulated: "قیمت دلار محاسبه شده",
  eurocalulated: "قیمت یورو محاسبه شده",
  rialpaid: "مقدار پرداختی ریالی",
  AdminDescription: "توضیحات ادمین",
  buyBtn: "پرداخت",
  TicketDepartments: {
    1: "حسابداری",
    2: "احراز هویت",
    3: "مبادله پرفکت مانی",
    4: "مبادله ارز های دیجیتال",
    5: "پرداخت قبوض",
    6: "گیفت کارت",
    7: "پرداخت ارزی",
  },
  tryafterMoment: "مشکلی پیش آمده لطفا چند ثانیه بعد مجددا امتحان کنید",
  ticketAwaitinguserRes: "در انتظار پاسخ کاربر",
  userLevelStr: "(کاربر سطح {0})",
  tickethasattachment: "فایل ضمیمه انتخاب شد",
  enterReplay: "پیام خود را بنویسید",
  walletStatus: {
    DONE: "موفق",
    PENDING: "در انتظار",
    BLOCKED: "بلوکه",
    BLOCKED_WD:'در انتظار واریز'
  },
  TransactionMode: {
    DEPOSIT_RIAL_GATEWAY: "درگاه پرداخت",
    DEPOSIT_SELL_CRYPTO: "فروش ارز",
    DEPOSIT_REFERRAL_CODE: "کد معرف",
    DEPOSIT_PERFECTMONEY: "فروش پرفکت مانی",
    WD_BUY_CRYPTO: "خرید ارز دیجیتال",
    WD_PERFECT_MONEY: "خرید پرفکت مانی",
    WD_GHABZINO: "قبض",
    WD_RIAL: "برداشت وجه",
    WD_GIFTCARD: "خرید گیفت کارت",
    WD_ONLINE_PAYMENT: "خرید ارزی",
    WD_CAR_GHABZINO: "خلافی خودرو",
    PAYBACK_ONLINE_PAYMENT: "برگشت پول برداخت ارزی",
    DEPOSIT_IBAN_PAY:'واریز شناسه دار',
    DEPOSIT_REJECTED_FACTOR:'مرجوعی فاکتور',
    WD_BUY_PAYEER:'خرید پاییر',
    DEPOSIT_SELL_PAYEER:'فروش پاییر',
    DEPOSIT_OFFLINE_PAY:'واریز آفلاین',
    WD_ADMIN:'برداشت توسط مدیریت',
    WD_BUY_UTOPIA:'خرید یوتوپیا',
    DEPOSIT_SELL_UTOPIA:"فروش یوتوپیا"
  },
  transTitle: "{0} به شناسه سفارش {1}",
  blockedwallat: "بلوکه شده",
  sourseAcc: "شماره حساب مبدا",
  redirectingtoGateway: "درحال انتقال به صفحه پرداخت...",
  wdamountIsntCorrect: "مبلغ برای برداشت صحیح نمیباشد",
  pending2: "در حال بررسی....",
  pending3: "در حال بررسی پرداخت <br> لطفا کمی صبر کنید....",
  walletCharged: "کیف پول شما با موفقیت شارژ شد",
  usd: "دلار",
  euro: "یورو",
  billServices: {
    water: "شناسه قبض آب",
    gas: "شناسه قبض گاز",
    mci: "شماره موبایل همراه اول",
    irancell: "شماره موبایل ایرانسل",
    hometell: "شماره تلفن ثابت",
    electric: "شناسه قبض برق",
  },
  midterm: "میان دوره",
  finalTerm: "پایان دوره",
  alreadyPaid: "پرداخت شده",
  inquireDetailsViolationItem1: "هزینه: 5,200 تومان",
  inquireDetailsViolationItem2: "پرداخت با تسویه آنی",
  inquireDetailsViolationItem3: "نمایش جزئیات کامل خلافی",
  inquireDetailsViolationItem4: "نمایش عکس تخلف",
  inquireTotalViolationItem1: "هزینه: 5,200 تومان",
  inquireTotalViolationItem2: "پرداخت با تسویه آنی",
  inquireTotalViolationItem3: "نمایش جزئیات کامل خلافی",
  termsitem1: "هزینه استعلام خلافی برای هر پلاک، 5,200 تومان.",
  termsitem2: "هزینه استعلام از کیف پول ریالی شما کسر می شود.",
  termsitem3: "امکان استفاده از این مبلغ برای پرداخت صورتحساب خلافی وجود ندارد.",
  termsitem4: " این مبلغ از سوی پلیس ناجی دریافت می‌شود و امکان بازگشت وجه پرداختی وجود ندارد.",
  termsitem5: "نمایش جرائم وسایل نقلیه دارای شکایت امکان‌پذیر نیست. در صورت ثبتشکایت برای وسیله نقلیه شما، جریمه‌ای نمایش داده نمی‌شود.",
  termsitem6: "در صورت پرداخت ناموفق، مبلغ کسرشده حداکثر تا ۷۲ ساعت به حساب شمابازگردانده می‌شود.",
  termsitem7: "نتایج پرداخت های شما در بخش سوابق سفارش ذخیره شده و در هر زمان قابل دسترسی است.",
  areYouSurePayBill: "آیا از پرداخت قبض اطمینان دارید؟",
  loadingdata: "درحال دریافت اطلاعات....",
  mailTellErr: "فرمت ورودی باید شماره تلفن یا ایمیل باشد",
  inviteSent: "دعوتنامه با موفقیت ارسال شد",
  highestPrice: "بالاترین قیمت (24H)",
  lowestPrice: "پایین‌ترین قیمت (24H)",
  purchasePriceTomans: "قیمت خرید تومان",
  sellPriceTomans: "قیمت فروش تومان",
  getPerfectMoney: " پرفکت مانی می‌گیرید",
  youPayTomans: " تومان می‌پردازید",
  dailybuyLimit: "محدودیت خرید روزانه:",
  limitExample: "0 تومان از 10,000,000 تومان پر شده",
  getccy: "{0} می‌گیرید",
  siteInventory: "موجودی فعلی سایت:",
  minAmount: " حداقل میزان خرید: ",
  dailyLimit: " سقف خرید روزانه: ",
  choseNetworkType: "نوع شبکه را انتخاب کنید",
  chooseNetworkTypeDes:
      "با توجه به حساسیت مجموعه‌های قانونگذار بین المللی بر فعالیت صرافی‌های ایرانی که دارای کیف پول ارزی هستند نظیر نوبیتکس، والکس، اکسکوینو و ... خواهشمندیم از هرگونه برداشت و واریز از این گونه صرافی‌ها به مهران بیت و برعکس به جهت حفظ دارایی خود جلوگیری کنید. در صورت انتقال از صرافی‌ها مذکور به مهران بیت و یا از مهران بیت به صرافی‌های مذکور مسئولیت از دست رفتن دارایی به عهده شماست و مهران بیت هیچ مسئولیتی در قبال بلوکه شدن دارایی ارزی شما ندارد.",
  noMemoTag: "تگ/ممو/کامنت ندارد",
  noMemoTagDes: "لطفا مطمئن شوید که ولت شما تگ ممو ندارد ، در غیر اینصورت مهران بیت هیچگونه مسئولیتی در قبال از دست رفتن دارایی شما ندارد.",
  networkFee: "کارمزد ارسال شبکه: {0} {1} ({2} تومان)",
  seeAllNews: "مشاهده همه اخبار",
  buyUs: "قیمت خرید از ما",
  sellingToUs: "قیمت فروش به ما",
  pmBalanceErr: "مبلغ وارد شده بیشتر از موجودی سایت میباشد",
  pmMinError:'حداقل میزان خرید پرفکت مانی 1 دلار میباشد',
  buyLimitReached:'تنها {0} تومان در روز میتوانید خرید کنید برای افزایش خرید روزانه سطح اکانت خود را ارتفا دهید',
  SellLimitReached:'تنها {0} تومان در روز میتوانید فروش داشته باشید برای افزایش خرید روزانه سطح اکانت خود را ارتفا دهید',
  WdLimitReached:'تنها {0} تومان در روز میتوانید برداشت ریالی باشید برای افزایش خرید روزانه سطح اکانت خود را ارتفا دهید',
  pmAddrErr: "آدرس مقصد وارد شده اشتباه است",
  pmTypes: {
    Transfer: "مستقیم",
    Voucher: "ووچر",
  },
  sellPerfectMoney: " پرفکت مانی میفروشید",
  getPayTomans: "تومان میگیرید",
  addressManagment: "",
  minOrderBuyCrypto: "حداقل میزان خرید {0} {1} عدد میباشد",
  maxOrderBuyCrypto: "حداکثر میزان خرید {0} {1} عدد میباشد",
  walletAddressInvalid: "آدرس ولت وارد شده برای شبکه {0} نمی باشد",
  buyCryptoCheckout: "خرید {0}",
  buyTempDisabled: "خرید این ارز موقطا غیر فعال میباشد",
  sellTempDisabled: "فروش این ارز موقطا غیر فعال میباشد",
  sTitle:'فروش ارز {0}',
  bTitle:'خرید ارز {0}',
  depositCryptoTitle:'{0} میپردازید',
  beingIntercepted: " در حال رهگیری وضعیت تراکنش شما هستیم ...",
  depositLogicStates:{
    0:'تراکنش دریافت شد (تایید اولیه بلاک)',
    1:'تراکنش دریافت شد ( در حال تکمیل بلاک ها)',
    2:'تراکنش دریافت شد (در حال تایید نهایی)',
    5:'تراکنش با خطا مواجه شد. لطفا با پشتیبانی در تماس باشید.',
    6:'تراکنش با خطا مواجه شد. لطفا با پشتیبانی در تماس باشید.',
    FIRST_BLOCK:'تراکنش دریافت شد (تایید اولیه بلاک)',
    DELAYED:'تراکنش دریافت شد ( در حال تکمیل بلاک ها)',
    PENDING:'تراکنش دریافت شد (در حال تایید نهایی)',
    REJECTED:'تراکنش با خطا مواجه شد. لطفا با پشتیبانی در تماس باشید.',
    SUSPENDED:'تراکنش با خطا مواجه شد. لطفا با پشتیبانی در تماس باشید.'
  },
  depositInErr:'خطا در دریافت ارز {0}، لطفا با پشتیبانی تماس بگیرید',
  depositFullyReceived1:'{0} {1} با موفقیت دریافت شد',
  depositFullyReceived2:'و مبلغ {0} تومان حساب شما شارژ شد',
  loginTakeTooLong:'لطفا کمی بیشتر صبر کنید',
  otpSent:'کد برای شما ارسال شد',
  giveNewPass:'دریافت رمز جدید',
  newPassSent:'رمز جدید برای شما ارسال شد',
  walletRemoved:'ولت مورد نظر با موفقیت حذف شد',
  sessionRemoved:'دستگاه مورد نظر با موفقیت حذف شد',
  sellcryptoNotice:'مبلغ تومانی اعلام شده تقریبی می باشد و با توجه به متغیر بودن نرخ ارز ها , در لحظه ای که شما ارز را به ولت اعلام شده واریز کردید نرخ دقیق محاسبه و کیف پول ریالی شما شارژ میگردد',
  introAppSlide1:'خرید و فروش بیش از 1600 رمز ارز',
  introAppSlide2:'امکان پرداخت قبوض و خرید گیفت کارت با ارز دیجیتال',
  introAppSlide3:'بهترین نرخ تبادل',
  introAppSlide4:'پشتیبانی 24 ساعته واقعی',
  buyFromUs:'خرید از ما',
  sellToUs:'فروش به ما',
  failedReasonDeposit:'علت : {0}',
  failedReasonsDeposit:{
    CANCELLED_BY_USER:'انصراف از پرداخت',
    TRANSACTION_TIMED_OUT:'عدم پرداخت در مدت زمان مقرر',
    PSP_PAYER_CARD_NOT_MATCHED:'پرداخت با کارت بانکی تایید نشده',
    UNKNOWN:'نامعلوم',
    "-1": "در انتظار پردخت",
    "-2": "خطای داخلی",
    "1": "پرداخت شده - تاییدشده",
    "2": "پرداخت شده - تاییدنشده",
    "3": "لغوشده توسط کاربر",
    "4": "پرداخت با کارت بانکی تایید نشده",
    "5": "موجودی حساب کافی نمی‌باشد.",
    "6": "رمز واردشده اشتباه می‌باشد.",
    "7": "تعداد درخواست‌ها بیش از حد مجاز می‌باشد.",
    "8": "تعداد پرداخت اینترنتی روزانه بیش از حد مجاز می‌باشد.",
    "9": "مبلغ پرداخت اینترنتی روزانه بیش از حد مجاز می‌باشد.",
    "10": "صادرکننده‌ی کارت نامعتبر می‌باشد.",
    "11": "خطای سوییچ",
    "12": "کارت قابل دسترسی نمی‌باشد."
  },
  status4Error:'مبلغ کسر شده طی ۱ الی ۷۲ ساعت به صورت خودکار  به حساب شما برگشت خواهد خورد',
  installPwa:'نصب وبسایت به صورت برنامه',
  installpwaIOS:'نصب برنامه برای IOS',
  installPwaBtn:'نصب ',
  vpnIsOn:'وی پی ان روشن است ادامه...',
  vpnDescription:'برای نصب به صورت آپ باید VPN خود را روشن کنید تا فرآیند نصب صورت گیرد در غیر این صورت به صورت HomeScreen در صفحه اول موبایل شما اضافه میشود',
  invalidPhoneNumber:'شماره وارد شده اشتباه است',
  paymentOk:'پرداخت موفق',
  carVPaymentOKTitle:'قبض انتخابی شما با موفقیت پرداخت شد',
  showOrderDetail:'مشاهده جزئیات پرداخت',
  payAnotherBill:'پرداخت قبض دیگر',
  giftcardProductEmpty:'محصول نا موجود',
  initCaptcha:'فیلد هارا پر کنید',
  nowalletSaved:'در حال حاضر آدرس کیف پول ارزی ثبت نکرده اید',
  scanQR:'با دوربین خود اسکن کنید !',
  carVioAllok:'پرداخت تمامی خلافی های خودرو با موفقیت انجام شد',
  errNetwork:'دسترسی به اینترنت را بررسی و مجدد تلاش کنید.',
  deleteFactor:'حذف فاکتور',
  deleteFactorconfirm:'آیا از حذف فاکتور مطمئن هستید؟',
  factorDeletec:'فاکتور {0} با موفقیت حذف شد',
  enterPlate:'لطفا همه فیلد های شماره پلاک خودرو را وارد کنید',
  tagmemoNotRequiredLbl:'تگ/ممو/کامنت ندارد',
  tagmemoNotRequiredWarning:'لطفا مطمئن شوید که ولت شما تگ ممو ندارد ، در غیر اینصورت مهران بیت هیچگونه مسئولیتی در قبال از دست رفتن دارایی شما ندارد.',
  iphoneSetupGuide:'وب‌اپلیکیشن مهران بیت را به صفحه اصلی تلفن همراه خود اضافه کنید',
  iphoneSetupGuideBrowser:'(از مرورگر سافاری یا گوگل کروم استفاده نمایید)',
  iphoneSetupP1:'Share',
  iphoneSetupP2:'انتخاب این گزینه ',
  iphoneSetupP3:'Add to homescreen',
  iphoneSetupP4:'انتخاب این گزینه از منوی ظاهرشده',
  iphoneSetupP5:'Add',
  iphoneSetupP6:'زدن این گزینه',
  errBuyContactsupport:'نیاز به تایید مدیریت ، با پشتیبانی در تماس باشید',
  rejectedFactorByAdmin:'فاکتور مرجوع شده',
  wdFailedSts:'Failed - با پشتیبانی تماس بگیرید',
  acceptTermsRegister:'شرایط و قوانین مهران بیت میپذیرم.',
  acceptTermsRegisterView:'( مشاهده )',
  shouldAcceptTerm:'باید قوانین و مقررات مهران بیت را تایید کنید',
  androidgoogleAuth:'Google Authenticator را از مارکت های معتبر دانلود کنید',
  errVerifyNetworkTitle:'خطا در اینترنت ',
  errVerifyNetwork:'برای تکمیل پرداخت خود بر روی دکمه زیر کلیک نمایید',
  errVerifyNetworkBtn:'تکمیل پرداخت',
  notSignedVerify:'برای خرید اینترنتی باید نرم افزار مهران بیت را آپدیت کنید و برای پرداختی فعلی با پشتیبانی تماس بگیرید',
  viollationAllCarTitle:'پرداخت مجموع خلافی',
  viollationAllCarTitleCheck:'استعلام مجموع خلافی',
  viollationDetailsCarTitleCheck:'استعلام جزئیات خلافی',
  carCheckTitle:' استعلام خلافی خودرو',
  walletIndexIquirtyCostTitle:'مبلغ {0} تومان بابت استعلام خلافی خودرو از کیف پول ریالی شما کسر شده است.',
  errMaxDepositErr:' سقف فروش روزانه شما بیشتر از حد مجاز می باشد',
  notAllowBuy:'خرید ارز برای این نماد غیر فعال است',
  notAllowSell:'فروش ارز برای این نماد غیر فعال است',
  gotoHome:'رفتن به خانه',
  callbackPayFactor1:'پرداخت موفقیت آمیز بود',
  callbackPayFactor2:'درحال تایید فاکتور',
  callbackPayFactorErr:'کیف پول ریالی شما شارژ شد ولی فاکتور شما تایید نشد میتوانید در لیست سفارشات با کیف پول خود پرداخت کنید',
  callbackPayFactorpaid:'فاکتور شما با موفقیت پرداخت شد',
  callbackPayFactorpaidbtn:'مشاهده جزئیات سفارش',
  walletIndexTitle:'مدیریت آدرس ولت ها',
  ticketNone:'در حال حاضر تیکتی ثبت نشده است',
  noNetworkExchangeAvailable:'درحال حاضر شبکه فعالی برای این ارز وجود ندارد',
  otpResetPassFailed:'کد احراز هویت اشتباه است',
  otpfieldTitle:'لطفا کد ارسال شده را وارد کنید',
  newVersionUpdate:'ورژن جدیدی از برنامه در دسترس می باشد. آیا تمایل بروز رسانی دارید ؟',
  depositAddresFailedFactor:'آدرس مقصد اشتباه است ',
  depositAddresFailedFactor2:'با پشتیبانی تماس بگیرید',
  perfectmoneyPayeeBlocked:'آدرس مقصد وارد شده مسدود شده یا نامعتبر است',
  nativePayCallbackFactorNote:'برای پرداخت فاکتور به برنامه مهران بیت برگردید',
  androidPayCheck:'در حال بررسی پرداخت ریالی ....',
  versionTitle:'نسخه برنامه : ',
  updateModalTitle:'نسخه جدید رسید',
  downloadMyket:'مایکت',
  downloadbazzar:'کافه بازار',
  downloadPWA:'نسخه PWA تحت وب',
  downloadAnarDoni:' اناردونی',
  downloadsibApp:' سیب آپ',
  downloadsibChe:' سیبچه',
  downloadiApp:'آی آپس',
  updateFrom:'بروز رسانی از :',
  secLv2Info1:'کاربر گرامی برای ارتقاء به سطح 2 می بایست در سطح فعلی خود حداقل مبلغ {0} تومان پرداخت انلاین داشته باشید.<br/><br/>مبلغ فعلی پرداخت انلاین شما : {1} ‌تومان',
  secLv2Info2:'کاربر گرامی، تا {0} ساعت و {1} دقیقه دیگر امکان ارتقاء حساب شما به سطح 2 فراهم خواهد شد.',
  maxDepositError:'در حال حاضر نمیتوانید بیشتر از 25 میلیون تومان در روز از طریق درگاه پرداخت نمایید ، میبایست کیف پول ریالی خود را شارژ کنید',
  maxDepositErrorShort:'در حال حاضر بیشتر از 25 میلیون تومان در روز نمیتوانید از درگاه پرداخت نمایید',
  attention:'توجه',
  NativePayNote1:'برای مشاهده اطلاعات فاکتور به برنامه مهران بیت برگردید',
  need_relogin:'لطفا مجددا وارد حساب کاربری خود شوید',
  errorAuthonPay:'کیف پول شما شارژ شد ولی فاکتور شما تایید نشد برای تایید دستی به قسمت سوابق سفارشات رفته و فاکتور خود را پرداخت کنید',
  depositMethod:'روش افزایش موجودی خود را انتخاب کنید : ',
  bankingPortal:'درگاه بانکی',
  bankingPortalLimitPrice:'سقف واریز روزانه',
  bankingPortalLimitPriceIban:'سقف واریز روزانه هر شماره شبا',
  bankingPortalFee2:'کارمزد بانکی : ',
  comingSoon:'به زودی',
  highAmountDepositTitle:'واریز شناسه دار',
  ultimate:'نامحدود',
  factorTotalAmount:'مبلغ فاکتور',
  rialgatewayFee:'کارمزد درگاه پرداخت',
  bankCardRequired:'برای پرداخت آنلاین می بایست حداقل یکی از کارت های بانکی خود را ثبت نمایید',
  bankcardManagment:'مدیریت کارت های بانکی',
  ref_copied:'لینک معرف کپی شد',
  backCallbackNotAllowed:'لطفا تا تکمیل تاییدیه پرداخت در این صفحه باقی بمانید',
  offer_title:'کد تخفیف',
  offer_placeholder:'کد تخفیف را وارد کنید',
  offer_btn:'اعمال',
  offer_info_invoice:'دلاری {0} تومان تخفیف اعمال شد',
  captchaError:'با توجه به اختلالات موجود در اینترنت برای لاگین از VPN استفاده کنید',
  WdfeePrice:'کارمزد تسویه بانکی',
  WdFinalPrice:'مبلغ خالص دریافتی',
  dpFeePrice:'کارمزد بانکی',
  modalgatewayInitTitle:'مدیریت حساب بانکی',
  modalgatewayInitDes:'کاربر گرامی واریز وجه تنها با کارت های ثبت شده در برنامه امکان پذیر می باشد، در غیر اینصورت کیف پول ریالی شما شارژ نمیگردد. ',
  modalgatewayInitCheckBox:'دیگر نمایش نده',
  enterAmount:'مبلغ را وارد کنید',
  enterAmount2:'مبلغ وارد شده باید بالای 10,000 تومان باشد',
  ibanpay:{
    levelOneNote:'( نیاز به ارتقاء به سطح 2 )',
    levelOneNote2:'راهنمای فعال سازی',
    activationTitle:'راهنما',
    activationNote:'برای فعال‌سازی واریز شناسه‌دار، باید حداقل یک فاکتور بالای ۲۰ میلیون تومان که ۷۲ ساعت از پرداخت آن گذشته باشد، داشته باشید.',
    warningTitle:'توجه کنید !',
    warningText:'لازم به ذکر است پس از پرداخت وجه و تایید بانک ، کیف پول ریالی شما در یکی از سیکل های پایای (۴:۳۰  صبح) (۱۲:۳۰ ظهر) و یا (۱۹:۳۰ عصر) بجز ایام تعطیل بصورت سیستمی شارژ خواهد شد.',
    warningText2:'شما میتوانید از هر شبای بانکی ثبت شده در سایت تا مبلغ 25,000,000 تومان در روز واریز نمایید. در صورتی که مبلغ واریزی بالاتر باشد , مبلغ واریز شده تا 5 روز  آینده به حساب شما بازگشت داده خواهد شد.',
    warningBtn:'متوجه شدم ',
    walletBalanceTitle:'موجودی کل',
    enterShaba:'شبای بانکی خود را انتخاب کنید:',
    selectSheba:'انتخاب شبای بانکی',
    shabaInfo:'توجه: مبلغ تومانی اعلام شده تقریبی میباشد و با توجه به متغیر بودن نرخ ارز ها، در لحظه ای که شما ارز را به ولت واریز کردید نرخ دقیق محاسبه و کیف پول شما شارژ میگردد',
    userNote:'کاربر گرامی توجه داشته باشید:',
    noteItem1:'لطفا از واریز وجه <b>بین بانکی پل</b> خودداری فرمایید.',
    noteItem2:'حتما از شبای بانکی انتخاب شده وجه خود را واریز کنید.',
    noteItem3:'شناسه واریز داده شده در صفحه بعد را در فرایند واریز بانکی ثبت نمایید. در صورتیکه هر یک از موارد بالا رعایت نشود وجه واریز شده پس از ۷۲ ساعت به حساب بانکی شما استرداد می شود.',
    noteItem4:'در واریز شناسه‌دار، با انتخاب بانک مبدا و مقصد یکسان، تراکنش سریع‌تر انجام خواهد شد.',
    noteItem5:'شما میتوانید از هر شبای بانکی ثبت شده در سایت تا مبلغ 25 میلیون تومان در روز واریز نمایید ( اگر شما 4 شماره شبا ثبت شده در سایت داشته باشید در مجموع مبلغ 100 میلیون تومان در روز میتوانید واریز شناسه دار انجام دهید )',
    noteItem6:'درصورتی که از هر شبا مبلغ بالاتر از 25 میلیون تومان واریز گردد ، سیستم به صورت خودکار مبلغ واریزی را تا 5 روز آینده به حساب شما بازگشت میدهد و کیف پول ریالی شما شارژ نخواهد شد . ',
    videoTitle:'آموزش واریز شناسه دار',
    getPayInfoTitle:'دریافت شناسه واریز',
    videoModalTitle:'آموزش دریافت شناسه واریز دار',
    payAttention:'توجه داشته باشید:',
    depositAttentionItem1:'واریز از روش هایی مانند چک بین بانکی - انتقال چک به حساب - واریز پول نقد و واریز بین بانکی پل منجر به استرداد وجه خواهد شد.',
    depositAttentionItem2:'چنانچه از حساب دیگری بجز حساب خودتان واریز را انجام دهید استرداد وجه پس از ۷۲ ساعت با کسر کارمزد پایا ، انجام خواهد شد.',
    depositAttentionItem3:'چنانچه از هر حساب مبلغ بالاتر از 25 میلیون تومان واریز نمایید استرداد وجه پس از ۷۲ ساعت با کسر کارمزد پایا ، انجام خواهد شد.',
    depositId:'شناسه واریز',
    ibanNumber:'شماره شبا',
    bank:'بانک:',
    depDesName:'صاحب حساب : تجارت الکترونیک ارسباران',
    chosePayInfo:'یکی از بانک ها را انتخاب کنید',
    errorSelectedCard:'لطفا شماره شبای بانکی خود را انتخاب نمایید',
  },
  memoCommentTagCaution:'در صورت وارد نکردن [تگ/ممو/کامنت] در پلتفرم برداشت ، دارایی شما مفقود می شود و مسئولیتی متوجه ما نمی باشد.',
  memoCommentTagCaution2:'در صورتی که پلتفرم مقصد از [تگ/ممو/کامنت] پشتیبانی می کند حتما آن را به درستی‌ وارد نمایید ، در غیر اینصورت دارایی شما مفقود و مسئولیتی متوجه ‌ما نخواهد بود.',
  substitute:{
    btnSubmit:'ثبت',
    header:'تغییر {0}',
    email:'ایمیل',
    telephone:'شماره موبایل',
    get_code:'ارسال کد',
    google_2fa:'کد Google Authenticator',
    current_telephone:'شماره تلفن فعلی',
    new_telephone:'شماره موبایل جدید',
    new_telephone_vcode:'کد پیامک شده به شماره موبایل جدید',
    new_telephone_placeholder:'کد پیامک شده به شماره جدید را وارد کنید',
    current_telephone_vcode:'کد پیامک شده به شماره {0}',
    current_telephone_placeholder:'کد پیامک شده به شماره فعلی خود را وارد کنید',
    current_email:'ایمیل فعلی',
    new_email:'ایمیل جدید',
    new_email_vcode:'کد ایمیل شده به ایمیل جدید',
    new_email_placeholder:'کد ارسال شده به ایمیل جدید را وارد کنید',
    current_email_vcode:'کد ایمیل شده به {0}',
    current_email_placeholder:'کد ارسال شده به ایمیل فعلی خود را وارد کنید',
    new_phone_note:'شماره موبایل جدید حتما باید به نام خودتان باشد!',
    errors:{
      no_google2fa:'برای تغییر ایمیل یا تلفن نیاز به فعال کردن Google Authenticator دارید',
      no_email:'ایمیلی فعلی ثبت نشده است',
      no_new_telephone:'شماره موبایل جدید را وارد کنید',
      no_new_email:'ایمیل جدید را وارد نکردید',
      format_new_email:'فرمت ایمیل جدید اشتباه است',
      format_new_telephone:'فرمت شماره همراه جدید اشتباه است',
      completeForm:'لطفا تمامی اطلاعات فرم را کامل کنید'
    },
    infos:{
      current_email:'فعلی شما',
      vcode_sent_telephone:'کد برای شماره {0} ارسال شد',
      vcode_sent_email:'کد برای ایمیل {0} ارسال شد',
      changed_telephone:'شماره تلفن شما با موفقیت تغییر یافت',
      changed_email:'ایمیل شما با موفقیت تغییر یافت',
    }
  },
  pmItem:{
    title:'پرفکت مانی',
    buy:'قیمت خرید',
    sell:'قیمت فروش'
  },
  payeer:{
    amount:'موجودی پاییر',
    indexTitle:'خرید و فروش پاییر',
    getPayeerTitle:'پاییر میگیرید',
    payeer_to_Title:'آدرس پاییر مقصد : ',
    payeer_to_Title_placeholder:'P123456789 : مثال ',
    buy_payeer:'خرید پاییر',
    sell_payeer:'فروش پاییر',
    sendTo:'ارسال به',
    status:'وضعیت',
    fromTo:'اکانت ارسالی',
    fee:'کارمزد ارسال',
    payeerCount:'مقدر پاییر',
    sellPayeerQa: " آیا از فروش پاییر خود مطمئن هستید؟",
    historyId:'کد تراکنش (Operation ID)',
    payeerFrom:'حساب کاربری ارسال کننده',
    sellPayeer:'پاییر میفروشید',
    historyIdPlaceHolder:'(History ID یا Operation ID) کد تراکنش ',
    sellPayeerInfo:'لطفا دلار پاییر خود را به حساب {0} {1} واریز نمایید و سپس مشخصات واریزی خود را ثبت نمایید',
    errors:{
      MinError:'حداقل میزان خرید پاییر 1 دلار میباشد',
      invalidPayeerId:'آدرس پاییر مقصد اشتباه است',
      moreThanBalance:'مقدار دلار وارد شده بیشتر از موجودی سایت میباشد',
      invalidPayeerId2:'آدرس مقصد وارد شده در سیستم پاییر وجود ندارد یا غیرفعال است'
    }
  },
  offlinedeposit:{
    layoutTitleHistory:'فیش های ثبت شده آفلاین',
    layoutTitleSubmit:'ثبت فیش واریزی',
    levelTwoNote:'( نیاز به ارتقاء به سطح 3 )',
    title:'واریز آفلاین',
    whatisTitle:'واریز آفلاین چیست ؟',
    limitless:'نامحدود',
    warningBtn:'متوجه شدم ',
    warningTitle:'توجه کنید !',
    warningText1:'حداقل واریز از روش واریز آفلاین 20 میلیون تومان و حداکثر ۳ میلیارد تومان است.',
    warningText2:' واریز باید از حساب‌های بانکی ثبت‌ شده به نام شما انجام شود ، در غیر این صورت مبلغ واریزی پس از ۷۲ ساعت به حساب فرستنده بازگردانده خواهد شد.',
    warningText3:'زمان حدودی بررسی و تایید فیش تا ۴ ساعت می باشد.',
    ourAccounts:'حساب های ما',
    depositNote1:'لطفا مبلغ {0} تومان را به یکی‌از شماره حساب های اعلامی زیر واریز نمایید',
    accNum:'شماره حساب:',
    submitOfflineDepositBtn:'ثبت فیش واریزی',
    helpNote:'واریز آفلاین چیست ؟',
    linkHistory:'مشاهده فیش های ثبت شده',
    helptext:'راهنمای واریز آفلاین',
    sentCardNum:'شماره شبا مبدا',
    accountantDes:'توضیحات کارشناس مالی',
    status:{
      0:'در حال بررسی',
      1:'تایید شده',
      2:'رد شده',
      3:'بازگشت وجه'
    },
    form:{
      amount:'مبلغ واریزی',
      enterAmountPlaceHolder:'مبلغ مورد نظر را وارد کنید',
      track_id:'شماره پیگیری فیش واریزی',
      enterTrackIdPlaceHolder:'شماره پیگیری را وارد کنید',
      date:'تاریخ واریزی',
      enterDatePlaceHolder:'1400/01/01',
      iban:'شبای بانکی خود را انتخاب کنید',
      enterIbanPlaceHolder:'انتخاب شبای بانکی',
      screenshot:'افزودن عکس فیش واریزی',
      scrrenshotNote:'آپلود (تا 5 مگابایت)',
      screenshotFileCondition:'حجم تصویر باید کمتر از ۵ مگابایت و در فرمت JPG, PNG و یا HEIC باشد.',
      screenShotPreview:'پیش‌ نمایش تصویر',
      submit:'ثبت فیش واریزی',
      submitDone:'فیش واریزی شما با موفقیت ثبت شد و پس از انجام بررسی، کیف پول ریالی شما شارژ خواهد شد.'
    },
    errors:{
      uploadPhoto:'عکس رسید تراکنش خود را باید ارسال کنید',
      selectIban:'کارت بانکی که میخواهید با آن واریز را انجام دهید را انتخاب کنید'
    },
    modal:{
      title:'تاییدیه',
      text:'کاربر گرامی، آیا واریز مبلغ {0} تومان با شماره پیگیری {1} توسط شما تأیید می‌شود؟',
      submitBtn:'تایید',
      editBtn:'ویرایش'
    },
    help:{
      title:'واریز آفلاین',
      beAdvised:' توجه داشته باشید:',
      note1:'سرویس واریز آفلاین برای واریز بیش از 20 میلیون تومان',
      note1S:' (کارمزد رایگان)',
      step1:'دریافت حساب مقصد',
      step2:'پرداخت وجه از حسابتان',
      step3:'بارگذاری تصویر فیش واریزی',
      htext1:'شماره حساب مقصد را دریافت کنید.',
      htext2:'به روش حساب به حساب , ساتنا یا پایا از هر حسابی به نام خودتان به حساب مقصد, از شعبه , اینترنت بانک , موبایل بانک وجه واریز کنید.',
      htext3:'فیش واریزی را بارگذاری کنید و منتظر تایید از سمت کارشناس مالی باشید. پس از دریافت وجه در حساب مقصد, موجودی شما در مهران بیت افزایش می یابد.',
      finalNote:'در این روش تنها امکان واریز مبالغ بالای ۲۰ میلیون تومان امکان پذیر هست',
      gotit:'متوجه شدم'
    }
  },
  utopia:{
    head_buy_title:'خرید یوتوپیا',
    head_sell_title:'فروش یوتوپیا',
    balance:'موجودی',
    index:'خرید و فروش یوتوپیا',
    buy_transfer_btn:'واریز به ولت',
    buy_voucher_btn:'کد یوتوپیا',
    buy_ern:'یوتوپیا میگیرید',
    sell_ern:'یوتوپیا میپردازید',
    wd_pk_title:'آدرس ولت',
    wd_pk_placeholder:'میباشد Public Key آدرس ولت همان ',
    voucher:'کد یوتوپیا',
    transfer:'ارسال مستقیم',
    VOUCHER:'کد یوتوپیا',
    TRANSFER:'ارسال مستقیم',
    buytype:'نوع خرید',
    des_pk:'آدرس مقصد',
    des_pk_source:'آدرس ارسال کننده ( مبدا )',
    showFactor:'مشاهده اطلاعات خرید یوتوپیا',
    inToman:'مبلغ به تومان',
    checkVoucherBtn:'بررسی وضعیت کد',
    voucherNotUsed:'کد یوتوپیا مورد نظر استفاده نشده است',
    voucherUsed:'تاریخ فعال سازی :',
    voucherUsedPk:'آدرس مقصد فعال شده :',
    sell_transfer_btn:'فروش مستقیم',
    sell_voucher_btn:'فروش کد یوتوپیا',
    sell_voucher_input_title:'کد یوتوپیا',
    sell_voucher_input_placeholder:'xxxx-xxxx-xxxx-xxxx : مثال ',
    only_usd_voucher_approved:'کد مورد نظر اشتباه وارد شده است ، کدی را وارد کنید که برای دلار باشد',
    sell_transfer_notice:'برای فروش مستقیم آدرس ولت زیر را کپی کرده و در مبدا در فیلد public key وارد کنید و سیستم پس از دریافت تراکنش کیف پولی ریالی شما را شارژ میکند',
    gen_pk_btn:'ساخت ولت اختصاصی',
    transfer_recived:'تراکنش دریافت شد',
    transfer_info:'مبلغ {0} دلار دریافت شد و کیف پول ریالی شما شارژ شد',
    your_pk:'آدرس ولت شما',
    confirm_sell_voucher:'آیا از فروش کد یوتوپیا خود مطمئن هستید ؟',
    alert_succ_voucher:'فروش شما با موفقیت انجام شد',
    errors:{
      amountMinError:'حداقل میزان خرید 1 دلار میباشد',
      wrongpk:'آدرس وارد شده اشتباه میباشد'

    }
  }
};

export default i18nLangs_fa;
